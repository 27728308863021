<template>
   <div class="container">
    <div class="menu">
      <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
        <MenuItem name="baseInfo">
          <Icon type="ios-information-circle" />
          基本信息
        </MenuItem>
        <MenuItem name="lease">
          <Icon type="ios-create" />
          租赁信息
        </MenuItem>
        <MenuItem name="writeInfo">
          <Icon type="ios-create" />
          填写设置
        </MenuItem>
        <MenuItem name="moreSetting">
          <Icon type="md-chatbubbles" />
          更多设置
        </MenuItem>
      </Menu>
    </div>
    <div class="content">
        <Form
        :model="formData"
        :label-colon="true"
        :label-width="120"
        v-show="showMenu == 'baseInfo'"
        :disabled="true"
      >
        <FormItem>
          <span slot="label" class="validate">标题:</span>
          <Input v-model.trim="formData.title"></Input>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">公布时间:</span>
          <DatePicker
            :options="dateOption"
            :transfer="true"
            v-model="formData.time"
            type="datetimerange"
            style="width: 400px"
            placeholder="请选择公布开始时间和结束时间"
          ></DatePicker>
          <Tooltip placement="top" :delay="500">
            <Icon type="md-help-circle" style="margin: 10px"></Icon>
            <div slot="content">
              <p>到了公共开始时间，终端上才可查看到；</p>
              <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
            </div>
          </Tooltip>
        </FormItem>
        <FormItem label="联系电话">
          <Input
            v-model.trim="formData.contactMobile"
            style="width: 200px"
          ></Input>
          <span style="color: #aaa; width: 200px; margin-left: 16px"
            >说明：移动端可以直接拨打</span
          >
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">发布终端:</span>
          <CheckboxGroup v-model="formData.terminal">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV</Checkbox>
            <!-- <Checkbox label="2">PC</Checkbox> -->
            <Checkbox label="3">APP</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
            <!-- <Checkbox label="5">公众号</Checkbox>
            <Tooltip placement="top" :delay="500">
              <Icon type="md-help-circle" style="margin: 10px"></Icon>
              <div slot="content">
                <p>
                  勾选公众号，则会对发布范围中关注过社区随约服务网上驿站公众号的用户主动推送此活动。
                </p>
              </div>
            </Tooltip> -->
          </CheckboxGroup>

        </FormItem>
        <FormItem label="发布范围">
          <span slot="label" class="validate">发布范围</span>
          <Select
            v-model="city"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择市"
            multiple
            filterable
            @on-change="changeCity"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="region"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择区"
            :disabled="regionDisabled"
            multiple
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="stree"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择街道/乡镇"
            :disabled="streeDisabled"
            multiple
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="project"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择社区"
            :disabled="projectDisabled"
            multiple
            filterable
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="封面图片">
          <span slot="label" class="validate">封面图片:</span>
          <img
            ref="image"
            :src="formData.photo"
            alt=""
            width="200"
            style="display: block; margin-bottom: 10px"
          />
        </FormItem>
        <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=商品详情（必填）"
          style="width: 100%; height: 500px; border: 0px"
          @load="loadUeditor"
        ></iframe> -->
        <iframe
          name="ueditor"
          id="ueditor"
          src="/mobileeditor/#/index?titlename=商品详情（必填）"
          style="width: 100%; height: 700px; border: 0px"
          @load="loadUeditor"
        ></iframe>
        <Card style="position: relative;top: -20px" dis-hover v-if="formData.operationVo">
          <p slot="title">拟制信息</p>
          <FormItem style="display: inline-block;width: 308px;margin-bottom: 0px" label="拟制员工">
            <Input  :value="formData.operationVo.createName || ''" disabled style="width: 200px"></Input>
          </FormItem>
          <FormItem style="display: inline-block;width: 450px;margin-bottom: 0px" label="拟制时间">
            <DatePicker
              type="datetime"
              :value="formData.operationVo.createTime | toggleTime(that)"
              disabled
              style="width: 300px"
            ></DatePicker>
          </FormItem>
        </Card>
        <Card
          style="position: relative;top: -20px;border-top: none"
          dis-hover
          v-if="formData.status == '2' || formData.status == '3' || formData.status == '4' || formData.status == '5'"
        >
          <p slot="title">审核信息</p>
          <FormItem style="display: inline-block;width: 308px;margin-bottom: 0px" label="审核员工">
            <Input v-if="formData.operationVo" :value="formData.operationVo.reviewName" disabled style="width: 200px"></Input>
          </FormItem>
          <FormItem v-if="formData.operationVo" style="display: inline-block;width: 450px;margin-bottom: 0px" label="审核时间">
            <DatePicker
              type="datetime"
              :value="formData.operationVo.reviewTime | toggleTime(that)"
              disabled
              style="width: 300px"
            ></DatePicker>
          </FormItem>
        </Card>
        <Card
          style="position: relative;top: -20px;border-top: none"
          dis-hover
          v-if="formData.status == '4' || formData.status == '5'"
        >
          <p slot="title">发布信息</p>
          <FormItem v-if="formData.operationVo" style="display: inline-block;width: 308px;margin-bottom: 0px" label="发布员工">
            <Input v-if="formData.operationVo" :value="formData.operationVo.publishName" disabled style="width: 200px"></Input>
          </FormItem>
          <FormItem v-if="formData.operationVo" style="display: inline-block;width: 450px;margin-bottom: 0px" label="发布时间">
            <DatePicker
              type="datetime"
              :value="formData.operationVo.publishTime | toggleTime(that)"
              disabled
              style="width: 300px"
            ></DatePicker>
          </FormItem>
        </Card>
        <Card
          style="position: relative;top: -20px;border-top: none"
          dis-hover
          v-if="formData.dismissList && formData.dismissList.length > 0"
        >
          <p slot="title">驳回信息</p>
          <div v-for="(item,index) in formData.dismissList" :key="index">
            <FormItem style="display: inline-block;width: 308px;margin-bottom: 0px" label="驳回员工">
              <Input :value="item.staffName" disabled style="width: 200px"></Input>
            </FormItem>
            <FormItem style="display: inline-block;width: 450px;margin-bottom: 0px" label="发布时间">
              <DatePicker
                type="datetime"
                :value="item.gmtCreate | toggleTime(that)"
                disabled
                style="width: 300px"
              ></DatePicker>
            </FormItem>
            <div style="color: red;margin: 20px 0 20px 48px;">
              驳回原因：
              <span>{{item.reason}}</span>
            </div>
            <Divider v-if="index != formData.dismissList.length -1" />
          </div>
        </Card>
      </Form>
      <keep-alive>
          <Form :model="formData"  v-if="showMenu == 'writeInfo'" :disabled="true">
        <!-- 表单模板 -->
        <div style="margin: 0 10px">
          <PreviewForm :previewFormData="formDetailVo"></PreviewForm>
        </div>
      </Form>
      </keep-alive>
      <!--填写设置-->
      <Form :model="formData" v-show="showMenu == 'writeInfo'" >
        <FormItem style="margin: 0 0 12px 10px;display: inline-block"  v-if="addChangeIden">
          <Button
            type="primary"
            @click="openFormTemp"
            >引用模板</Button
          >
        </FormItem>
        <!-- 表单模板 -->
        <div style="margin: 0 10px">
          <AddForm v-if="changeFormShow === false" :hiddenTitle="true" ref="addChangeForm" :formDetailVo2="formDetailVo"></AddForm>
          <ChangeForm v-if="changeFormShow === true" :hiddenTitle="true" :infoId="infoId" :formDetailVo2="formDetailVo" ref="addChangeForm"></ChangeForm>
        </div>
      </Form>
      <!--更多设置-->
      <Form
        :model="formData"
        v-show="showMenu == 'moreSetting'"
        :label-colon="true" 
        :label-width="150"
        :disabled="true"
      >
        <FormItem>
          <span slot="label" class="validate">信息来源:</span>
          <Input v-model="formData.source"></Input>
        </FormItem>
        <FormItem label="参与获得积分">
          <InputNumber style="width:200px" :max='999999999' :min="1" v-model="ruleVos[1].ruleValue"></InputNumber>
        </FormItem>
        <FormItem>
          <Checkbox v-model="ruleVos[0].ruleValue">实名认证</Checkbox>
          <span style="color: #aaa;margin-left: 16px">注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，不勾选则只可获得报名用户的手机号</span>
        </FormItem>
        <FormItem label="按钮名称">
          <Select :value="ruleVos[2].ruleValue" style="width:200px" transfer @on-change="changeRuleVos2">
            <Option value="立即租赁">立即租赁</Option>
            <Option value="我要租赁">我要租赁</Option>
            <Option value="自定义按钮名称">自定义按钮名称</Option>
          </Select>
          <span style="color: #aaa;margin-left: 16px">注：用户租赁的时候看到的按钮名称</span>
        </FormItem>
        <FormItem v-if="ruleVos[2].ruleValue == '自定义按钮名称'">
          <span slot="label" class="validate">名称:</span>
          <Input v-model="formData.buttonName" :maxlength="10" placeholder="限10字" style="width:200px"></Input>
        </FormItem>
      </Form>
      <!--租赁信息-->
      <Form
       :model="formData"
        v-show="showMenu == 'lease'"
        :label-colon="true"
        :label-width="150"
        :disabled="true">
           <FormItem>
            <span slot="label" class="validate">租赁押金:</span>
             <InputNumber style="width:200px" :max='999999999' :min="1" v-model="formData.goodsInfo.mortgage"></InputNumber><span style="margin-left:10px">元</span>
          </FormItem>
           <FormItem>
            <span slot="label" class="validate">租赁扣费:</span>
            <InputNumber style="width:200px" :max='999999999' :min="1" v-model="formData.goodsInfo.leasePrice"></InputNumber><span style="margin-left:10px">元</span>
            <Select :value="formData.goodsInfo.leaseModule" style="width:100px;margin-left:10px" transfer>
                <Option value="1">/天</Option>
                <Option value="2">/时</Option>
              </Select>
          </FormItem>
           <FormItem>
            <span slot="label">线下库存:</span>
            <InputNumber style="width:100px" placeholder="请输入线下库存" :max='999999999' :min="1" v-model="formData.goodsInfo.goodsNum"></InputNumber><span style="margin-left:10px">件</span>
          </FormItem>
           <FormItem>
            <span slot="label"  class="validate">启用状态:</span>
             <Select :value="formData.goodsInfo.status" style="width:300px" transfer >
                <Option value="1">上架</Option>
                <Option value="2">下架</Option>
              </Select>
          </FormItem>
          <FormItem>
              <span slot="label" class="validate">商品分类:</span>
              <Select :value="formData.subType" style="width:200px" transfer>
                <Option v-for="(item,index) in meunParamsList" :key="index" :value="item.value">{{item.label}}</Option>
              </Select>
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">是否推荐:</span>
              <RadioGroup v-model="formData.recommend">
                <Radio label="1">推荐</Radio>
                <Radio label="0">不推荐</Radio>
              </RadioGroup>
            </FormItem>
            <FormItem>
              <span slot="label">推送方式:</span>
              <Checkbox v-model="formData.pushChannel">小程序订阅通知</Checkbox>
            </FormItem>
      </Form>
    </div>
     <LiefengModal
      title="驳回原因"
      :value="rejectStatus"
      @input="rejectStatusFn"
      width="500px"
    >
       <template v-slot:contentarea>
         <Mymodel
             :fullscreen="false"
             :value="toggle1"
             :toggle="true"
             :textObj="textObj"
             title="文本预览"
             :toggle-fn2="toggleFn2"
         >
         </Mymodel>
         <Input v-model.trim="reason"></Input>
       </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          @click="
            cancelModalTip = true;
            rejectStatusFn(false);
          "
          style="margin-right: 10px"
          >取消</Button
        >
        <Button type="primary" @click="publish('')"
          >确定</Button
        >
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengEditor from "@/components/LiefengEditor";
import LiefengModal from "@/components/LiefengModal";
import PreviewForm from '@/views/form/childrens/previewForm'
import Mymodel from "@/views/activity/Mymodel";

export default {
  props: ['menuCodeObj',"activeId","infoStatus","publicRecommend"],
  data() {
    return {
      that: this,
      //富文本预览
      toggle1: false, //
      textObj: {
        spinShow: false,
        signInUrl: '', //预览二维码url
      },
      showMenu: "baseInfo",
      formData: {terminal: [], time2: [], time: [], pushChannel: false}, //新增修改时提交的数据第一层
      cycleDates: [], //重复内容--日期 / 周
      cycleDateList: (() => {
        let arr = [];
        for (let i = 1; i <= 32; i++) {
          arr.push({
            value: i,
            label: i,
          });
        }
        return arr;
      })(),
      cycleTimeList: [
        { value: 1, label: "周一" },
        { value: 2, label: "周二" },
        { value: 3, label: "周三" },
        { value: 4, label: "周四" },
        { value: 5, label: "周五" },
        { value: 6, label: "周六" },
        { value: 7, label: "周日" },
      ],
      cycleTimes: [
        {
          limits: [
            {
              attrType: "person",
              attrValue: "",
            },
          ],
          timeValue: [],
        },
      ], //开展时间
      dateOption: {
        shortcuts: [
          //公布时间配置项
          {
            text: "1天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
              return [start, end];
            },
          },
          {
            text: "7天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "30天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "365天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
              return [start, end];
            },
          },
        ],
      },
      // 发布终端临时变量
      terminalList: [],
      dataScopeIdList: [], //发布范围列表
      city: [],
      region: [],
      stree: [],
      project: [],
      cityList: [],
      regionList: [],
      streeList: [],
      projectList: [],

      regionDisabled: true,
      streeDisabled: true,
      projectDisabled: true,
      // 上传图片
      action: "",
      file: "",
      imageName: "",
      imagePath: "",
      uploadData: {},
      ruleVos: [
        //规则
        {
          ruleCode: "authentication", //实名认证
          ruleValue: "",
        },
        {
          ruleCode: 'participate_integral"', //参与获得积分
          ruleValue: 0,
        },
        {
          ruleCode: "button_name", //按钮名称
          ruleValue: "",
        },
        {
          ruleCode: "whether_registration", //代报名
          ruleValue: "",
        },
        {
          ruleCode: "registration_type", //代报填写信息
          ruleValue: "",
        },
        {
          ruleCode: "signature", //填报用户签名确认
          ruleValue: "",
        },
        {
          ruleCode: "submit_only", //限制每人限填一次
          ruleValue: "",
        },
      ],
      // 模板弹窗
      formTemplateStatus: false,
      formTemplateDate: [],
      page: 1,
      pageSize: 9,
      total: 0,
      tabName: 0,
      changeFormShow: false, //控制显示新增还是修改表单组件
      infoId: "",
      formDetailVo: {}, //切换菜单时暂存模板表单数据
          // 修改必传id
        appointmentId: '',

        // 驳回
      reason: "",
      rejectStatus: false,
      cancelModalTip: true,

       // 商品分类
      meunParamsList:[]
    };
  },
  methods: {
    toggleFn2() {
      this.toggle1 = false
    },
    // 驳回
    rejectStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.reason = '';
            this.rejectStatus = status;
          },
        });
      } else {
        this.rejectStatus = status;
      }
    },
    reject() {
        this.rejectStatus = true;
    },
    passAndSend(){
      this.$Modal.confirm({
        title: "加急说明",
        content: "<p>1.经审核提交发布的合格内容，在点击【加急】按钮后，该信息将通过【加急】通道，在15分钟内完成发布流程。</p><p> 2.根据市民政局主管部门的批准意见，【加急】发布的信息，仅限于疫情防控或类似需要紧急发布的信息，具体标准由审核人员参照批准意见加强管控。</p>",
        onOk: () => {
           this.addPush(1)
        }
      })    
    },
    // 发布
    publish(releaseWay) {
      if(this.formData.pushChannel == true){
        // this.$Modal.confirm({
        //   title: "退出提示",
        //   content: "<p>当前发布包括推送，是否继续？</p>",
        //   onOk: () => {
              this.addPush(releaseWay || '')
        //   }
        // })
      }else{
            this.addPush(releaseWay || '')
      }
    },
    addPush(releaseWay){
        if(this.rejectStatus && !this.reason) {
                  this.$Message.error({
                    background: true,
                    content: "请输入驳回原因"
                  })
                  return
                }
              this.$post("/datamsg/api/pc/information/goodsLease/change", {
                status:
                  this.rejectStatus
                    ? "2"
                    :
                    this.infoStatus == "待审核"
                    ? '3'
                    : this.infoStatus == "待发布"
                    ? "4"
                    : this.infoStatus == "已发布"
                    ? "5"
                    :this.infoStatus == "已过期"
                    ?'6'
                    : "",
                recommend: this.publicRecommend && this.publicRecommend != '' ? this.publicRecommend : this.formData.recommend,
                sort: this.formData.sort,
                id: this.activeId,
                staffName: parent.vue.loginInfo.userinfo.realName,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                terminal: "2",
                reason: this.reason,
                pushChannel: this.formData.pushChannel ? '4' : '',
                releaseWay:releaseWay ? releaseWay : '',
                pushType: this.formData.pushType && this.formData.pushType[0] ? this.formDat.pushType[0]: "",
                channels: this.formData.pushType && this.formData.pushType[0] == 3 ? this.formData.channels : "",
              }).then((res) => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "操作成功",
                  });
                  this.cancelModalTip = false;
                  this.$emit("saveSuccess");
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              });
    },
    loadUeditor() {
      if (
          this.formData.content != "" &&
          this.formData.content != null &&
          this.formData.content != undefined
      ) {
        var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
        v_Obj.setContent(this.formData.content); //写入编辑器富文本内容
        v_Obj.setPreview(true);
      } else {
        setTimeout(this.loadUeditor, 300);
      }
    },
    contentFn() {   //富文本预览
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formData.content = v_Obj.getContent(); //获取富文本内容

      if (!this.formData.content) {
        this.$Message.warning({
          background: true,
          content: '暂没内容，点击编辑吧！',
          duration: 2
        });
        return
      }
      this.$Message.loading({
        background: true,
        content: '正在加载，请稍等...'
      });


      let params = {
        content: this.formData.content
      }
      this.$post(
          "/datamsg/api/pc/wxqr/createWxAppletQr",
          {
            type: "RICH_TEXT_PREVIEW_PAGE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath:
                  "/indexPages/previewRichText/index",
              params: {
                ...params,
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        if (res.code == 200 && res.data) {
          this.textObj.signInUrl = res.data.codeUrl;
          this.toggle1 = true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    // tab切换
    toggleTab(number) {
      this.tabName = number;
      this.handleTableData({
        page: this.page,
        pageSize: this.pageSize,
      });
    },

    // 切换菜单
    selectMenu(name) {
      this.showMenu = name;
    },
    // 城市选择
    changeCity(code) {
      if (code.length >= 2) {
        this.regionList = [];
        this.streeList = [];
        this.projectList = [];
        this.region = [];
        this.stree = [];
        this.project = [];
        this.regionDisabled = true;
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.regionDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }
    },
    // 区选择
    changeRegion(code) {
      if (code.length >= 2) {
        this.streeList = [];
        this.projectList = [];
        this.stree = [];
        this.project = [];
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.streeDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }
    },
    // 街道选择
    changeStree(code) {
      if (code.length >= 2) {
        this.projectList = [];
        this.project = [];
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.projectDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }
    },
    // 获取发布范围列表
    getPulishData(code, list, model) {
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
            };
          });
          if (res.dataList.length == "1") {
            this[model] = [res.dataList[0].orgPath];
          }
        }
      });
    },
    // 获取搜索级联
    async getMenuParam(){
     await this.$get('/old/api/pc/platform/column/selectChildColumnCode',{
       columnCode:this.menuCodeObj.columnCode,
      //  orgCode:parent.vue.loginInfo.userinfo.orgCode
     }).then(res=>{
         this.meunParamsList = []
         res.dataList.map(item =>{
            this.meunParamsList.push({
              value:item.columnCode,
              label:item.columnName
            })
          })   
      })
    }
  },
  async created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    await this.getMenuParam()
    await this.getPulishData("44", "cityList", "city");
     if(this.activeId) {
      await this.$get('/datamsg/api/pc/information/v2/queryById',{
         infoId: this.activeId,
         operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
         orgCode: parent.vue.loginInfo.userinfo.orgCode,
         oemCode: parent.vue.oemInfo.oemCode,
         businessType: this.menuCodeObj.businessType,
         functionType: this.menuCodeObj.functionType,
         terminal: '2'
       }).then(res => {
           if (res.code == 200 && res.data) {
          this.$Message.destroy();
          if (res.data.dataScopeList && res.data.dataScopeList.length == 1) {
            //回显发布范围
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city = item;
                  break;
                case 3:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = item;
                  break;
                case 4:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree = item;
                  break;
                case 5:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree =
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3];
                  this.project = item;
                  break;
              }
            });
          } else if (res.data.dataScopeList &&res.data.dataScopeList.length > 1) {
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city.push(item);
                  break;
                case 3:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(item);
                  break;
                case 4:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(item);
                  break;
                case 5:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                  );
                  this.project.push(item);
                  break;
              }
            });
            this.city = Array.from(new Set(this.city));
            this.region = Array.from(new Set(this.region));
            this.stree = Array.from(new Set(this.stree));
            this.project = Array.from(new Set(this.project));
          }
          this.formData = {
            operationVo: res.data.operationVo,
            dismissList: res.data.dismissList ? res.data.dismissList : [],
            title: res.data.title,
            recommend: res.data.recommend,
            pushChannel: res.data.pushChannel === '4' ? true : false, //小程序订阅通知
            time: [new Date(res.data.startTime), new Date(res.data.endTime)],
            contactMobile: res.data.contactMobile,
            terminal: res.data.terminal.split(",").indexOf("0") != -1
                ? ["0", "1", "2", "3", "4", "5"]
                : res.data.terminal.split(","),
            photo: res.data.photo,
            content: res.data.content,
            source: res.data.source,
            goodsInfo:res.data.goodsInfo,
            subType:res.data.subType,
            sort:res.data.sort,
            status:res.data.status
          };

          if (res.data.formDetailVo) {
            this.formDetailVo = res.data.formDetailVo;
            this.changeFormShow = true; // 切换为表单修改组件
          }
          if (res.data.ruleVos) {
            res.data.ruleVos.map((item) => {
              if (item.ruleCode == "authentication") {
                this.ruleVos[0] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue == "true" ? true : false,
                };
              } else if (item.ruleCode == "participate_integral") {
                this.ruleVos[1] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue ? Number(item.ruleValue) : 0,
                };
              } else if (item.ruleCode == "button_name") {
                if (
                  item.ruleValue == "立即租赁" ||
                  item.ruleValue == "我要租赁" 
                ) {
                  this.ruleVos[2] = {
                    ruleCode: item.ruleCode,
                    ruleValue: item.ruleValue,
                  };
                }else {
                   this.ruleVos[2] = {
                  ruleCode: item.ruleCode,
                  ruleValue: '自定义按钮名称',
                };
                this.formData.buttonName = item.ruleValue;
                }
              } else if(item.ruleCode == "whether_registration") {
                this.ruleVos[3] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              }else if (item.ruleCode == "registration_type") {
                this.ruleVos[4] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              } else if (item.ruleCode == "signature") {
                this.ruleVos[5] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
                 if(item.ruleValue == '1' && this.formDetailVo.interactionFormVos) {  //需要签名
                let index = this.formDetailVo.interactionFormVos.findIndex(res => res.content == 'signature');
                 this.formDetailVo.interactionFormVos.splice(index,1);
                 console.log(this.formDetailVo);
                }
              } else if (item.ruleCode == "submit_only") {
                this.ruleVos[6] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              }
            });
          }
        }

      })
     }
  },
  components: {
    LiefengEditor,
    LiefengModal,
    PreviewForm,
    Mymodel
  },
  filters: {
    toggleTime(val, that) {
      return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
    }
  },
};
</script>

<style scoped lang='less'>
.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    padding-top: 20px;
    flex: 1;
    height: 100%;
  }
}
/deep/.ivu-tooltip-inner {
  max-width: 100%;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #22a96c;
  }
}
.dateTime {
  width: 372px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    width: 50px;
    height: 40px;
    line-height: 40px;
    margin: 6px;
    text-align: center;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #72a2d4;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.dateWeek {
  width: 550px;
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    flex: 1;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #72a2d4;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.tempContainer {
  /deep/.ivu-tabs-content {
    height: calc(100vh - 122px);
    .tempBox {
      height: calc(100% - 35px);
      overflow: auto;
      min-height: 200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      & > div {
        width: 32%;
        margin: 0.5%;
        .ivu-card-head {
          padding: 10px;
          text-align: center;
        }
        .ivu-card-body {
          padding: 10px;
          line-height: 30px;
          min-height: 142px;
          height: 100%;
          overflow: auto;
        }
      }
      .noList {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
  /deep/.ivu-input[disabled],/deep/.ivu-input-number-disabled,/deep/.ivu-input-number-input[disabled], /deep/fieldset[disabled] .ivu-input,/deep/.ivu-checkbox-disabled .ivu-checkbox-inner,/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,/deep/.ivu-select-disabled .ivu-select-selection,/deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff;
    color: #333;
}
</style>
