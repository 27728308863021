<template>
  <div>
    <LiefengContent
      v-if="!(this.$route.query.id && this.$route.query.menuId && this.$route.query.status)"
    >
      <template v-slot:title>{{menuCodeObj.columnName || '商品管理'}}</template>
      <template v-slot:toolsbarRight>
        <Form :model="searchData" :label-colon="true" :inline="true" class="search">
          <FormItem>
            <Input
              v-model="searchData.title"
              placeholder="请选择商品名称"
              style="width:100px;text-align:left"
            />
          </FormItem>
          <FormItem>
            <Cascader
              transfer
              v-model="searchData.dataScopeList"
              change-on-select
              :load-data="loadCommunity"
              :data="releaseScopeList"
              placeholder="请选择发布范围"
              style="text-align:left"
            ></Cascader>
          </FormItem>
          <FormItem>
            <Select transfer v-model="searchData.goodsStatus" style="width:100px;text-align:left" placeholder="请选择状态">
                <Option value="" >全部</Option>
                <Option value="1" >上架</Option>
                <Option value="2" >下架</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Select transfer v-model="searchData.subType" style="width:100px;text-align:left" placeholder="请选择子栏目">
                <Option v-for="(item,index) in meunParamsList" :key="index" :value="item.value" >{{item.label}}</Option>
            </Select>
            
          </FormItem>
          <Button type="primary" icon="ios-search" @click="search" style="margin-right: 10px">查询</Button>
          <Button type="success" @click="reset" icon="ios-refresh" style="margin-right: 10px">重置</Button>
          <Button type="success" @click="$router.push(`/accessoryorder?menuId=${$route.query.menuId}`)"  style="margin-right: 10px">预约记录</Button>
          <!-- <Button
            type="primary"
            icon="ios-cube-outline"
            @click="$router.push({path: '/formindex',query: {path:$route.path,menuId: $route.query.menuId}})"
            style="margin-right: 10px"
          >表单模板库</Button> -->
          <!-- <Button
            type="primary"
            icon="ios-cube-outline"
            @click="openNumCount"
            style="margin-right: 10px" 
            v-if="buttonRoot == '1003'"
          >行为数据分析</Button> -->
          <Button
            icon="ios-add"
            type="primary"
            v-if="buttonRoot == '1001'"
            @click="
            addChangeTitle = '新增商品';
            activeId = '';
            addChangeIden = true;
            addChangeStatus = true;
          "
          >新增</Button>
        </Form>
      </template>
      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          :loading="loading"
          :fixTable="true"
          :curPage="page"
          :total="total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="pageSize"
          @hadlePageSize="hadlePageSize"
          @tableSelect="tableSelect"
        ></LiefengTable>
      </template>
    </LiefengContent>
    <!-- 新增修改 -->
    <LiefengModal
      :title="addChangeTitle"
      :value="addChangeStatus"
      @input="addChangeStatusFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <AddChange
          v-if="addChangeStatus"
          :activeId="activeId"
          :addChangeIden="addChangeIden"
          @saveSuccess="saveSuccess"
          :menuCodeObj="menuCodeObj"
          ref="addChange"
        ></AddChange>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          @click="
              cancelModalTip = true;
              addChangeStatusFn(false);
            "
          style="margin-right: 10px"
        >取消</Button>
        <Button type="primary" @click="save">保存</Button>
<!--        <Button type="warning" style="margin-left: 10px" @click="contentFn">预览文本</Button>-->
      </template>
    </LiefengModal>
    <!-- 审核/发布 -->
    <LiefengModal
      :title="publishTitle"
      :value="publishStatus"
      @input="publishStatusFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <Publish
          v-if="publishStatus"
          :activeId="activeId"
          :infoStatus="infoStatus"
          @saveSuccess="saveSuccess"
          :menuCodeObj="menuCodeObj"
          :publicRecommend="publicRecommend"
          ref="publish"
        ></Publish>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" @click="publishStatusFn(false)" style="margin-right: 10px">取消</Button>
        <Button
          type="warning"
          @click="reject"
          style="margin-right: 10px"
          v-if="infoStatus == '待审核' || infoStatus == '待发布'"
        >驳回</Button>
        <Button type="success" @click="publish">
          {{
          publishButtonName
          }}
        </Button>
        <Button type="warning" style="margin-left: 10px" v-if="infoStatus == '待审核' && buttonRoot == '1002'" @click="passAndSend">通过并加急发布</Button>
      </template>
    </LiefengModal>
    <!-- 查看 -->
    <LiefengModal title="查看详情" :value="seeStatus" @input="seeStatusFn" :fullscreen="true">
      <template v-slot:contentarea>
        <Publish  ref="publish1" v-if="seeStatus" :activeId="activeId" :menuCodeObj="menuCodeObj"></Publish>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          :style="{marginRight: buttonRoot == '1001' ? '10px' : ''}"
          @click="seeStatusFn(false)"
        >关闭</Button>
        <Button
          type="primary"
          :style="{display: buttonRoot == '1001' ? '' : 'none'}"
          @click="seeToChange"
        >修改</Button>
<!--        <Button type="warning" style="margin-left: 10px" @click="contentFn1">预览文本</Button>-->
      </template>
    </LiefengModal>
    <!-- 公众号链接 -->
    <LiefengModal
      title="分享并预览"
      :value="officialStatus"
      @input="officialStatusFn"
      width="1020px"
      height="calc(100vh - 100px)"
      class="signInOut"
    >
      <template v-slot:contentarea>
        <div>
          <Select
            v-model="officialRegion"
            transfer
            style="width: 270px"
            placeholder="请选择区"
            filterable
            @on-change="(val) => getOfficialScopeList(signInObj.id,'4',val)"
          >
            <Option
              :value="item.orgCode"
              v-for="(item, index) in officialRegionList"
              :key="index"
            >{{ item.orgName }}</Option>
          </Select>
          <Select
            v-model="officialStree"
            transfer
            style="width: 270px"
            placeholder="请选择街道/乡镇"
            filterable
            @on-change="(val) => getOfficialScopeList(signInObj.id,'5',val)"
          >
            <Option
              :value="item.orgCode"
              v-for="(item, index) in officialStreeList"
              :key="index"
            >{{ item.orgName }}</Option>
          </Select>
          <Select
            v-model="officialProject"
            transfer
            :label-in-value="true"
            style="width: 270px"
            :max-tag-count="2"
            placeholder="请选择社区"
            multiple
            filterable
            @on-change='changeProject'
          >
            <Option
              :value="item.orgCode"
              v-for="(item, index) in officialProjectList"
              :key="index"
            >{{ item.orgName }}</Option>
          </Select>
          <Button type="primary" style="margin: 0 10px" @click="makeOfficialQRCode">生成链接</Button>
        </div>
        <div v-if="codeImgUrl && officialUrl">
          <h3 style="margin-top:10px">该链接可以用于配置到公众号进行推广:</h3>
          <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span>公众号链接：</span>
            <Input
              v-model="officialUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyShareCode"
            ></Input>
          </div>
          <!-- <div
            style="
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              margin: 14px auto;
            "
          >
            <span style="width:84px">短信链接：</span>
            <Input
              v-model="noteUrl"
              search
              enter-button="复制"
              style="flex: 1;margin-right: 100px"
              @on-search="copyNoteUrl"
            ></Input>
          </div> -->
          <h3>推广二维码已生成，可下载后直接分享二维码到微信（朋友、朋友圈）</h3>
          <div >
             <div style="display: flex; align-items: center; justify-content: center">
                <div class="qrcode" ref="haibaoImg">
                  <div class="h1h3">
                    <h1>{{qrOCdeName | spliceTitle}}</h1>
                    <!-- <h3>入驻{{qrOCdeName}}</h3> -->
                  </div>
                  <!-- qrOCdeName -->
                  <p>微信扫码查看详情服务</p>
                  <span style="letter-spacing:3px">{{qrCodeOrgName }}</span>
                  <img
                    :src="codeImgUrl + '?' + new Date().getTime()"
                    alt
                    ref="qrCode"
                    crossorigin="anonymous"
                  />
                </div>
                <div style="width: 130px; height: 100%; margin-left: 30px">
                  <Button
                    type="primary"
                    style="margin-bottom: 20px; width: 130px"
                    @click="downloadImgCode"
                  >下载纯二维码</Button>
                  <Button type="warning" @click="mergeImage">下载宣传二维码</Button>
                </div>
              </div>
            <!-- <img :src="codeImgUrl" alt width="200" style="display: block;margin-bottom: 10px" />
            <Button type="primary" @click="downloadImgCode">下载二维码</Button> -->
          </div>
        </div>
        <div
          v-else
          style="height:calc(100vh - 305px);overflow: auto; margin: 20px;border-radius: 4px;position: relative;display: flex;justify-content: center;align-items: center;background: #ccc;color: #fff;font-size: 20px"
        >
          可生成街道或者社区的推广链接和二维码，区、街道为单选，社区可多选
          <Spin fix v-if="spinShow">
            <Icon type="ios-loading" size="28" class="demo-spin-icon-load"></Icon>
            <div style="font-size: 18px">正在为您生成推广链接和二维码，请稍等...</div>
          </Spin>
        </div>
      </template>
    </LiefengModal>
    <!-- 相关链接 -->
    <LiefengModal
      title="相关链接"
      :value="aboutLinkStatus"
      @input="aboutLinkStatusFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <AboutLink
          ref="aboutLink"
          @saveSuccess="saveSuccess"
          v-if="aboutLinkStatus"
          :menuCodeObj="menuCodeObj"
          :infoId="infoId"
          :infoCode="infoCode"
        ></AboutLink>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          @click="cancelModalTip = true;aboutLinkStatusFn(false)"
          style="margin-right: 10px"
        >取消</Button>
        <Button type="primary" @click="aboutLinkSave">保存</Button>
      </template>
    </LiefengModal>
    <!-- zhw新增 -->
    <!--更换栏目-->
    <LiefengModal title="更换栏目" :value="ChangeColumn" @input="ChangeColumnFn" :fullscreen="false">
      <template v-slot:contentarea>
        <div style="height:200px">
          <Cascader
            style="width:50%;padding-top:80px;margin-left:120px"
            :data="cascaderData"
            v-model="cascadValue"
          ></Cascader>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button
          type="info"
          @click="cancelModelTip = true ;ChangeColumnFn(false)"
          style="margin-right: 10px"
        >取消</Button>
        <Button type="primary" @click="changeSave">保存</Button>
      </template>
    </LiefengModal>
    <!--用户操作记录-->
    <LiefengModal
      title="用户操作记录"
      :value="userChooseTle"
      @input="UserChooseFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <CheckRecord
          :businessType="sendMessage.businessType"
          :infoId="sendMessage.infoId"
          :isShow="isOpenUserMessage"
          @userSave="userSave"
        ></CheckRecord>
      </template>
    </LiefengModal>
    <!--行为数据分析-->
    <LiefengModal
      title="行为数据分析"
      :value="numFenxiStatus"
      @input="numFenxiFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <Analysis  :isTrue='isTrue'  :infoIdLis='infoIdLis'></Analysis>
      </template>
      <template v-slot:toolsbar>
        <Button type="primary" @click="numFenxiFn(false)">关闭</Button>
      </template>
    </LiefengModal>
    <!-- 导出弹窗 -->
    <LiefengModal
      title="导出"
      :value="exportExcelStatus"
      @input="exportExcelStatusFn"
      :fullscreen="false"
    >
      <template v-slot:contentarea>
        <div style="height:300px;">
          <Form
            :label-colon="true"
            :label-width="100"
            style="padding-right:20px"
          >
            <FormItem label="所属社区">
              <Select
                v-model="marital"
                placeholder="请选择社区"
                filterable
                @on-change="selectCommunity"
              >
                <Option
                  v-for="(item, index) in communityList"
                  :key="index"
                  :value="item.code"
                  >{{ item.fullName }}</Option
                >
              </Select>
            </FormItem>
          </Form>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button  type="info" style="margin-right: 10px" @click="exportExcelTip= true;exportExcelStatusFn(false)">取消</Button>
        <Button type="primary" @click="canfirmExport">确认导出</Button>
      </template>
    </LiefengModal>


     <!--对比分析-->
    <LiefengModal
      title="对比分析"
      :value="contrastStatus"
      @input="contrastFn"
      :fullscreen="true"
    >
      <template v-slot:contentarea>
        <contrast  :infoId="contrastInfoId" :functionType='conFunctionType' :businessType='conBusinessType' :isShow="contrastShow"></contrast>
      </template>
      <template v-slot:toolsbar>
        <Button type="primary" @click="contrastClose">返回</Button>
      </template>
    </LiefengModal>




  </div>
</template>

<script>
//@route('/questionnaireindex')
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
import AddChange from "@/views/accessoryrental/shop/childrens/addChange";
import Publish from "@/views/accessoryrental/shop/childrens/publish";
import AboutLink from "@/views/accessoryrental/shop/childrens/aboutLink";
import CheckRecord from "@/views/accessoryrental/shop/childrens/checkRecord";
import Analysis from '@/views/accessoryrental/shop/childrens/analysis'
import contrast from '@/views/accessoryrental/shop/childrens/contrast'
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      searchData: { dataScopeList: [] },
      // 发布范围
      releaseScopeList: [],
      talbeColumns: [

        {
          title: "标题",
          key: "title",
          minWidth: 200,
          align: "center"
        },
        {
          title: "编码",
          key: "code",
          width: 100,
          align: "center"
        },
        {
          title: "发布范围",
          key: "releaseScope",
          width: 180,
          align: "center"
        },
        {
          title: "公布时间",
          key: "startTime",
          width: 180,
          align: "center"
        },
        {
          title: "截至时间",
          key: "endTime",
          width: 180,
          align: "center"
        },
        {
          title: "实名认证",
          key: "authentication",
          width: 100,
          align: "center"
        },
        {
          title: "拟制人",
          key: "createName",
          width: 100,
          align: "center"
        },
         {
          title: "推荐",
          key: "recommend",
          width: 165,
          align: "center",
          render: (h, params) => {
            return (
              "div",
              [
                h(
                  "RadioGroup",
                  {
                    attrs: {
                      value: params.row.recommend,
                    },
                    on: {
                      "on-change": (status) => {
                        this.infoId = params.row.id;
                        this.recommend = status;
                        this.sortNum = params.row.sort;
                        this.sortChangeFn({
							pushChannel: params.row.pushChannel
						});
                      },
                    },
                  },
                  [
                    h(
                      "Radio",
                      {
                        attrs: {
                          label: "1",
                           disabled: params.row.status == '已发布' || params.row.status == '已过期' ? false : true
                        },
                      },
                      "推荐"
                    ),
                    h(
                      "Radio",
                      {
                        attrs: {
                          label: "0",
                          disabled: params.row.status == '已发布' || params.row.status == '已过期' ? false : true
                        },
                      },
                      "不推荐"
                    ),
                  ]
                ),
              ]
            );
          },
        },
        {
          title: "排序",
          key: "sort",
          width: 110,
          align: "center",
          render: (h, params) => {
            return h("InputNumber", {
              attrs: {
                min: 1,
                max: 999999999,
                value: params.row.sort,
                precision: 0
              },
              style: {
                width: "100%"
              },
              on: {
                "on-change": val => {
                  if (val == params.row.sort) return;
                  this.recommend = params.row.recommend;
                  this.sortNum = val;
                  this.infoId = params.row.id;
                  clearTimeout(this.timer);
                  this.timer = setTimeout(() => {
                    this.sortChangeFn({
						pushChannel: params.row.pushChannel
					});
                  }, 1000);
                }
              }
            });
          }
        },
        {
          title: "审核状态",
          key: "status",
          width: 120,
          align: "center"
        },
        {
          title: "操作",
          align: "center",
          width: 180,
          fixed: "right",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "left"
                }
              },
              [
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small"
                    },
                    on: {
                      click: () => {
                        this.activeId = params.row.id;
                        this.addChangeIden = false;
                        this.addChangeTitle = "修改商品";
                        this.changeActivity();
                      }
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display: this.buttonRoot == "1001" ? "" : "none"
                    }
                  },
                  "修改" //1001
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small"
                    },
                    on: {
                      click: () => { 
                        this.activeId = params.row.id;
                        this.publishTitle = "审核商品";
                        this.infoStatus = params.row.status;
                        this.publishButtonName = "通过";
                        this.publishStatus = true;

                        this.publicRecommend = ''
                        this.publicRecommend = params.row.recommend
                      }
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1002" &&
                        params.row.status == "待审核"
                          ? ""
                          : "none"
                    }
                  },
                  "审核" //1002
                ),
                h(
                  "Button",
                  {
                    attrs: {
                      type: "info",
                      size: "small"
                    },
                    on: {
                      click: () => {
                        this.publishTitle = "发布商品";
                        this.activeId = params.row.id;
                        this.infoStatus = params.row.status;
                        this.publishButtonName = "发布";
                        this.publishStatus = true;
                        this.publicRecommend = ''
                        this.publicRecommend = params.row.recommend
                      }
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1003" &&
                        params.row.status == "待发布"
                          ? ""
                          : "none"
                    }
                  },
                  "发布" //1003
                ),
                h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true
                      // trigger: "click",
                    }
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          type: "success",
                          size: "small",
                          icon: "ios-arrow-down"
                        }
                      },
                      "编辑"
                    ),
                    h(
                      "DropdownMenu",
                      {
                        slot: "list"
                      },
                      [ 
                        h(
                          "DropdownItem",
                          {
                                nativeOn: {
                                  click: () => {
                                    this.publishTitle = "过期活动";
                                    this.activeId = params.row.id;
                                    this.infoStatus = params.row.status;
                                    this.publishButtonName = "过期";
                                    this.publishStatus = true;
                                  }
                                },
                                style: {
                                   display:
                                   params.row.status == '已发布' ?'block' :'none',
                                  textAlign: "center",
                                }
                          },
                          "过期"
                        ),
                        h(
                          "DropdownItem",
                          {
                                nativeOn: {
                                  click: () => {
                                    this.publishTitle = "归档活动";
                                    this.activeId = params.row.id;
                                    this.infoStatus = params.row.status;
                                    this.publishButtonName = "归档";
                                    this.publishStatus = true;
                                  }
                                },
                                style: {
                                   display:
                                   params.row.status == '已过期'?'block' :'none',
                                  textAlign: "center",
                                }
                          },
                          "归档"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.activeId = params.row.id;
                                this.seeStatus = true;
                              }
                            },
                            style: {
                              textAlign: "center"
                            }
                          },
                          "查看"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$router.push(`/readunread?type=shop&msgId=${params.row.id}&menuId=${this.$route.query.menuId}`)
                              },
                            },
                            style: {
                              textAlign: "center",
                               display:
                              params.row.status == "已发布" ||
                              params.row.status == "已过期"
                                ? ""
                                : "none",
                            },
                          },
                          "已读未读"
                        ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.contrastStatus = true
                        //         this.contrastShow = true
                        //         this.contrastInfoId = params.row.id
                        //         this.conBusinessType = params.row.businessType
                        //         this.conFunctionType = params.row.functionType
                        //       }
                        //     },
                        //     style: {
                        //       textAlign: "center"
                        //     }
                        //   },
                        //   "对比分析"
                        // ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.changeRow = {
                        //           id: params.row.id,
                        //           businessType: params.row.businessType,
                        //           orgCode: params.row.orgCode
                        //         };

                        //         this.ChangeColumn = true;
                        //         this.getColumnTree();
                        //       }
                        //     },
                        //     style: {
                        //       textAlign: "center"
                        //     }
                        //   },

                        //   "更换栏目"
                        // ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.sendMessage = {
                        //           businessType: params.row.businessType,
                        //           infoId: params.row.id
                        //         };
                        //         this.isOpenUserMessage = true;
                        //         this.modelTitle = params.row.title;
                        //         this.userChooseTle = true;
                        //       }
                        //     }
                        //   },
                        //   "用户操作记录"
                        // ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.qrOCdeName = params.row.title
                        //         this.$Message.loading({
                        //           background: true,
                        //           content: "正在加载数据，请稍等...",
                        //           duration: 0
                        //         });
                        //         this.signInObj = {
                        //           id: params.row.id,
                        //           businessType: params.row.businessType,
                        //           code: params.row.code
                        //         };
                        //         this.$get(
                        //           "/old/api/pc/information/getInformationScopeLevel",
                        //           {
                        //             informId: params.row.id
                        //           }
                        //         )
                        //           .then(res => {
                        //             if (res.code == 200) {
                        //               this.scopeStatus = res.data;
                        //               this.getOfficialScopeList(
                        //                 params.row.id,
                        //                 "3"
                        //               );
                        //             } else {
                        //               this.$Message.destroy();
                        //               this.$Message.error({
                        //                 background: true,
                        //                 content: res.desc
                        //               });
                        //             }
                        //           })
                        //           .catch(err => {
                        //             console.log(err);
                        //             this.$Message.destroy();
                        //             this.$Message.error({
                        //               background: true,
                        //               content: "获取数据失败，请联系管理员处理"
                        //             });
                        //           });
                        //       }
                        //     },
                        //     style: {
                        //       textAlign: "center"
                        //     }
                        //   },
                        //   "分享并预览"
                        // ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.infoCode = params.row.code;
                        //         this.infoId = params.row.id;
                        //         this.aboutLinkStatus = true;
                        //       }
                        //     },
                        //     style: {
                        //       textAlign: "center"
                        //     }
                        //   },
                        //   "配置相关链接"
                        // ),
                        // h(
                        //   "DropdownItem",
                        //   {
                        //     nativeOn: {
                        //       click: () => {
                        //         this.$router.push({
                        //           path: "/questionnairecollect",
                        //           query: {
                        //             id: params.row.id,
                        //             menuId: this.$core.getUrlParam("menuId")
                        //           }
                        //         });
                        //       }
                        //     },
                        //     style: {
                        //       textAlign: "center",
                        //       display:
                        //         params.row.status == "已发布" ||
                        //         params.row.status == "已发布" ||
                        //         params.row.status == "已归档"
                        //     }
                        //   },
                        //   "采集信息"
                        // ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                 this.$Modal.confirm({
                                  title: "温馨提示",
                                  content:
                                    "<p>确认是否进行拟制机构推送操作？</p>",
                                      onOk: () => {
                                        this.$post('/datamsg/api/pc/information/v2/pushAccurateInformation',{
                                          orgCodeList:params.row.orgCode,
                                          informationId:params.row.id,
                                          functionType:params.row.functionType
                                        }).then(res=>{
                                          if(res.code == 200){
                                            this.$Message.success({
                                              content:'操作成功',
                                              background:true
                                            })
                                            this.hadlePageSize({
                                              page: this.page,
                                              pageSize: this.pageSize
                                            });
                                            return
                                          }else{
                                            this.$Message.error({
                                              content:'操作失败',
                                              background:true
                                            })
                                            return
                                          }
                                        })
                                      }
                                 })
                              }
                            },
                            style: {
                              textAlign: "center",
                              display:this.buttonRoot == "1003" ? 'block' : 'none'
                            }
                          },
                          "拟制机构推送"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.infoId = params.row.id;
                                this.$Modal.confirm({
                                  title: "温馨提示",
                                  content:
                                    "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                                  onOk: () => {
                                    this.$post(
                                      "/info/api/pc/information/v2/deleteById",
                                      {
                                        infoId: this.infoId,
                                        operatorCustGlobalId:
                                          parent.vue.loginInfo.userinfo
                                            .custGlobalId,
                                        orgCode:
                                          parent.vue.loginInfo.userinfo.orgCode,
                                        oemCode: parent.vue.oemInfo.oemCode,
                                        businessType: this.menuCodeObj
                                          .businessType,
                                        functionType: this.menuCodeObj
                                          .functionType,
                                        terminal: "2"
                                      }
                                    ).then(res => {
                                      if (res.code == 200) {
                                        this.$Message.success({
                                          background: true,
                                          content: "删除成功"
                                        });
                                        this.hadlePageSize({
                                          page: this.page,
                                          pageSize: this.pageSize
                                        });
                                      } else {
                                        this.$Message.success({
                                          background: true,
                                          content: res.desc
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            },
                            style: {
                              textAlign: "center"
                            }
                          },
                          "删除"
                        )
                      ]
                    )
                  ]
                )
              ]
            );
          }
        }
      ],
      tableData: [],
      loading: false,
      page: 1,
      total: 0,
      pageSize: 20,
      // 菜单数据
      menuCodeObj: {},
      //   新增/修改
      infoId: "",
      addChangeIden: false,
      addChangeTitle: "",
      addChangeStatus: false,
      cancelModalTip: false,

      // 查看
      seeStatus: false,

      // 三级审核
      buttonRoot: "",
      status: "",

      //   驳回/发布
      publishTitle: "",
      publishStatus: false,
      infoStatus: "",
      publishButtonName: "",

      // 公众号链接
      officialStatus: false,
      officialUrl: "",
      codeImgUrl: "",
      officialRegion: "",
      officialRegionList: [],
      officialStree: "",
      officialStreeList: [],
      officialProject: [],
      officialProjectList: [],
      spinOfficialShow: false,
      signInObj: {},
      spinShow: false,
      // 相关链接
      aboutLinkStatus: false,
      infoCode: "",

      //排序定时器
      timer: null,
      //推荐/排序
      recommend: "",
      infoId: "",
      sortNum: "",

      // zhw新增
      ChangeColumn: false,
      cancelModelTip: false,
      changeRow: {},
      // 级联选择器的数据
      cascaderData: [],
      cascadValue: [],

      // 用户操作记录
      userChooseTle: false,
      cancelModelUser: false,
      modelTitle: "", //用户操作纪律弹窗标题
      sendMessage: {
        businessType: "",
        infoId: ""
      },
      isOpenUserMessage: false, //是否点击了用户操作按钮
      selectList:[],
      numFenxiStatus:false, //数据分析模态框
      infoIdLis:'',

      // 二维码
      qrOCdeName:'',
      qrCodeOrgName:'',
      codeImgUrl:'',
      changeVal:'',
      projectList:[],


      // 导出按钮弹窗
      exportExcelStatus:false,
      exportExcelTip:false,
      exportList:{
        id:''
      },
      //excel导出弹窗  社区数据
      communityList: [],
      // 选择社区的code
      marital:'',
       isTrue:0,

      // 对比分析
      contrastStatus:false,
      contrastInfoId:'',
      contrastShow:false,
      conFunctionType:'',
      conBusinessType:'',


      // publish的recommend按钮
      publicRecommend:'',

      // 选择框的级联
      meunParamsList:[],

      // 短信链接
      noteUrl:'',
      noteCode:'',
    };
  },
  methods: {
    // 关闭分析模态框
    contrastClose(){
      this.contrastShow = false
      this.contrastStatus=false
    },
    // 对比分析模态框关闭事件
    contrastFn(status){
      this.contrastStatus = status
      // this.contrastShow = false
    },


    selectCommunity(code) {
      this.marital = code;
    },
    // 导出弹窗关闭模态框事件
    exportExcelStatusFn(status){
      if(!status && this.exportExcelTip){
         this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.exportExcelStatus = status
            this.exportExcelTip =false
            this.marital = ''
            this.exportList.id  = ''
          }
        });
      }else{
         this.exportExcelStatus = status
         this.exportExcelTip =false
      }       
    },
    // 确认导出按钮
    canfirmExport(){
      
       if(this.marital == ''){
         this.$Message.warning({
           content:'请选择社区',
           background:true
         })
         return
       }else{
         let data = {
         id:this.exportList.id,
         orgCode:this.marital
       }
       this.exportBtn(data)
       }
       
      //  console.log(this.exportList.id)
      //  console.log(this.marital);
       
    },
    // 选择街道获取的数据
    changeProject(val){
      this.projectList = val
    },
    // 点击下载宣传二维码
    mergeImage() {
      html2canvas(this.$refs.haibaoImg, { useCORS: true }).then(function(
        canvas
      ) {
        let src = canvas.toDataURL();
        var image = new Image();

        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = function() {
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          var url = canvas.toDataURL("image/png", 1.0);
          var a = document.createElement("a");
          var event = new MouseEvent("click");
          a.download = "公众号宣传二维码";
          a.href = url;
          a.dispatchEvent(event);
        };
        image.src = src;
      });
    },
    numFenxiFn(status){
      this.numFenxiStatus = status
    },
    // 点击行为数据按钮
    openNumCount(){
      if(this.selectList.length == 0){
        this.$Message.warning({
          content:'请选择后进行点击',
          background:true
        })
        return
      }else{
        this.numFenxiStatus = true
        let List = []
        this.isTrue += 1
        this.selectList.map(item=>{
          List.push(item.id)
        })
        this.infoIdLis = List.join(',')
        console.log(this.infoIdLis);
      }
    },
    // 选择了什么
    tableSelect(val){
      this.selectList = val
    },
    //排序
    sortChangeFn(obj) {
      let params = {
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        id: this.infoId, //当前信息类id
        staffName: parent.vue.loginInfo.userinfo.realName,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        sort: this.sortNum,
        recommend: this.recommend,
		pushChannel: obj.pushChannel
      };

      this.$post("/datamsg/api/pc/information/goodsLease/change", params)
        .then(res => {
          if (res.code == 200) {
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize
            });
            this.$Message.success({
              background: true,
              content: "修改成功"
            });
          } else {
            this.$Message.error({
              background: true,
              content: "修改失败"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 查看
    seeStatusFn(status) {
      this.seeStatus = status;
    },
    // 查看跳去修改
    seeToChange() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0
      });
      this.seeStatus = false;
      this.addChangeTitle = "修改商品";
      this.addChangeStatus = true;
    },
    // 相关链接
    aboutLinkStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.aboutLinkStatus = status;
          }
        });
      } else {
        this.aboutLinkStatus = status;
      }
    },
    aboutLinkSave() {
      this.$refs.aboutLink.save();
    },
    // 公众号链接
    makeOfficialQRCode() {
      if (!this.officialRegion) {
        this.$Message.error({
          background: true,
          content: "请至少选择一个范围"
        });
        return;
      } 
      // else if (this.scopeStatus == "5" && this.officialProject.length == 0) {
      //   this.$Message.error({
      //     background: true,
      //     content: "该活动只发布给社区，请选择社区"
      //   });
      //   return;
      // }
      this.spinShow = true;

      this.officialStreeList.map(item =>{
        if(item.orgCode == this.changeVal){
          this.qrCodeOrgName = item.orgName
        }
      })
      let List = []
      this.projectList.map(item=>{
        List.push(item.value)
      })
      if(List.length == 1){
        this.officialProjectList.map(item=>{
          if(item.orgCode == List[0]){
            this.qrCodeOrgName = item.orgName
          }
        })
      }else{
         let newArr =this.officialProjectList.filter(item=> List.indexOf(item.orgCode)>-1)
         let List2 = []
         newArr.map(item=>{
            List2.push(item.orgName)
         })
         this.qrCodeOrgName = List2.join(',')
         console.log('获取的newArrList',List);
         console.log('拿到的社区码',this.officialProjectList)

      }


      // console.log('获取的List',List);
      // console.log('社区吗',this.projectList);
      // console.log('街道码',this.changeVal)
      // console.log('街道列表',this.officialStreeList)
      // console.log('拿到的社区码',this.officialProjectList)

      let params = {};
      if (this.officialProject.length > 0) {
        params = {
          communityList: (() => {
            let result = [];
            this.officialProjectList.map(item => {
              if (this.officialProject.indexOf(item.orgCode) != -1) {
                result.push({
                  projectCode: item.mappingCode,
                  projectName: item.orgName
                });
              }
            });
            return result;
          })()
        };
      } else {
        params.streetCode = this.officialStree;
      }
      this.$post(
        "/datamsg/api/pc/wxqr/createWxAppletQr",
        {
          type: "ACTIVITY_SHARE",
          value: JSON.stringify({
            path: "pages/welcome/welcome",
            redirectPath:
              "/mainTaskPages/modules/infoCollection/pages/detail/index",
            params: {
              ...params,
              code: this.signInObj.code,
              // recommendId: this.signInObj.id
            }
          })
        },
        { "Content-Type": "application/json" }
      ).then(res => {
        if (res.code == 200 && res.data) {
          this.codeImgUrl = res.data.codeUrl;
          this.officialUrl = "pages/welcome/welcome?scene=" + res.data.code;
          this.spinShow = false;
          this.noteCode = res.data.code
          // this.$post(
          //   "/verifyapi/api/sytop/pc/urlLink/createUrlLink",
          //   {
          //     path:'pages/welcome/welcome',
          //     query: `scene=${this.noteCode}`,
          //   },
          // ).then(res => {
          //   if (res.code == 200 && res.data) {
          //     this.noteUrl = res.data.link
          //   }
          // });
        }
      });
    },
    officialStatusFn(status) {
      if (!status) {
        this.spinOfficialShow = false;
        this.codeImgUrl = "";
        this.officialUrl = "";
        this.scopeStatus = "";
        this.signInObj = "";
        this.officialRegion = "";
        this.officialRegionList = [];
        this.officialStree = "";
        this.officialStreeList = [];
        this.officialProject = [];
        this.officialProjectList = [];
      }
      this.officialStatus = status;
    },
    getOfficialScopeList(informId, orgLevel, orgCodes) {

      if (!informId || (!orgCodes && (orgLevel == "4" || orgLevel == "5")))
        return;
      this.changeVal = orgCodes
      this.$get("/old/api/pc/information/getInformationScopeList", {
        informId: informId,
        orgLevel: orgLevel,
        orgCodes: orgCodes
      })
        .then(res => {
          if (res.code == 200 && res.dataList) {
            if (orgLevel == "3") {
              if (res.dataList.length == 1) {
                this.officialRegion = res.dataList[0].orgCode;
                this.getOfficialScopeList(informId, "4", this.officialRegion);
              }
              this.officialRegionList = res.dataList;
              this.officialStreeList = [];
              this.officialStree = "";
              this.officialProject = [];
              this.officialProjectList = [];
            } else if (orgLevel == "4") {
              if (res.dataList.length == 1) {
                this.officialStree = res.dataList[0].orgCode;
                this.getOfficialScopeList(informId, "5", this.officialStree);
              }
              this.officialStreeList = res.dataList;

              this.officialProject = [];
              this.officialProjectList = [];
            } else {
              if (res.dataList.length == 1) {
                this.officialProject.push(res.dataList[0].orgCode);
              }
              this.officialProjectList = res.dataList;

            }
            this.$Message.destroy();
            this.officialStatus = true;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理"
          });
        });
    },
    downloadImgCode() {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = "公众号推广二维码";
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = this.codeImgUrl;
    },
    copyShareCode() {
      var oInput = document.createElement("input");
      oInput.value = this.officialUrl;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$Message.success({
        background: true,
        content: "复制链接成功！"
      });
    },
    // 点击复制短信链接
    copyNoteUrl() {
      var oInput = document.createElement("input");
      oInput.value = this.noteUrl;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$Message.success({
        background: true,
        content: "复制链接成功！"
      });
    },
    // 发布/审核
    publishStatusFn(status) {
      if (
        this.$route.query.id &&
        this.$route.query.menuId &&
        this.$route.query.status &&
        !status
      ) {
        var index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        parent.layer.close(index); //再执行关闭
      } else {
        this.publishStatus = status;
      }
    },
    reject() {
      this.$refs.publish.reject();
    },
    publish() {
      this.$refs.publish.publish();
    },
    // 通过并发布按钮
    passAndSend(){
      this.$refs.publish.passAndSend()
    },
    // 修改
    changeActivity() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0
      });
      this.addChangeStatus = true;
    },
    reset() {
      this.searchData = { dataScopeList: [] };
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize
      });
    },
    // 新增/修改
    addChangeStatusFn(status) {
      if (
        this.$route.query.id &&
        this.$route.query.menuId &&
        this.$route.query.status &&
        !status
      ) {
        var index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        parent.layer.close(index); //再执行关闭
      } else if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.addChangeStatus = status;
          }
        });
      } else {
        this.addChangeStatus = status;
      }
    },
    contentFn() { //预览文本
      this.$refs.addChange.contentFn();
    },
     contentFn1() { //预览文本
      this.$refs.publish1.contentFn();
    },
    save() {
      this.$refs.addChange.save();
    },
    // 新增修改,配置相关链接成功后执行
    saveSuccess() {
      this.cancelModalTip = false;
      this.addChangeStatus = false;
      this.publishStatus = false;
      this.aboutLinkStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$get("/datamsg/api/pc/information/goodsLease/queryDataPage", {
        ...this.searchData,
        dataScopeList:
          this.searchData.dataScopeList.length > 0
            ? this.searchData.dataScopeList[
                this.searchData.dataScopeList.length - 1
              ]
            : "",
        oemCode: parent.vue.oemInfo.oemCode,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        columnCode: this.menuCodeObj.columnCode,
        page: obj.page,
        pageSize: obj.pageSize,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        terminal: "2"
      })
        .then(res => {
          this.loading = false;
          if (res.code == 200 && res.dataList) {
            this.tableData = res.dataList.map(item => {
              return {
                orgCode: item.orgCode,
                businessType: item.businessType,
                title: item.title,
                code: item.code,
                releaseScope: item.releaseScope,
                startTime: this.$core.formatDate(
                  new Date(item.startTime),
                  "yyyy-MM-dd hh:mm:ss"
                ),
                endTime: this.$core.formatDate(
                  new Date(item.endTime),
                  "yyyy-MM-dd hh:mm:ss"
                ),
                id: item.id,
                sort: Number(item.sort),
                recommend: item.recommend,
                status: (() => {
                  switch (item.status) {
                    case "1":
                      return "待审核";
                    case "2":
                      return "审核不通过";
                    case "3":
                      return "待发布";
                    case "4":
                      return "已发布";
                    case "6":
                      return "已过期";
                    case "5":
                      return "已归档";
                  }
                })(),
                authentication: item.ruleVos
                  ? (() => {
                      let str = "";
                      item.ruleVos.map(subItem => {
                        if (subItem.ruleCode == "authentication") {
                          if (subItem.ruleValue == "true") {
                            str = "是";
                          } else {
                            str = "否";
                          }
                        }
                      });
                      return str;
                    })()
                  : "",
                createName: item.operationVo ? item.operationVo.createName : "",
                functionType : item.functionType ? item.functionType : ''
              };
            });
            this.page = res.currentPage;
            this.pageSize = res.pageSize;
            this.total = res.maxCount;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败，请重试"
          });
        });
    },
    // 获取区
    getPulishData(code, list) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          this.releaseScopeList = res.dataList.map(item => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: []
            };
          });
        }
      });
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1
              };
            });
          } else {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    // zhw新增
    // 更换栏目
    // 点击关闭模态框的方法
    ChangeColumnFn(status) {
      console.log("获取模态框状态", status);
      if (!status && this.cancelModelTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.ChangeColumn = status;
            this.cascadValue = [];
          }
        });
      } else {
        this.ChangeColumn = status;
      }
    },
    // 更换栏目模态框保存按钮
    changeSave() {
      let menuId = "";
      if (this.cascadValue.length == 0) {
        this.$Message.warning({
          content: "请选择后进行保存！",
          background: true
        });
        return;
      } else {
        menuId = this.cascadValue.pop();
        console.log(menuId);
      }
      this.$post("/datamsg/api/pc/information/changeColumn", {
        businessType: this.changeRow.businessType,
        infoId: this.changeRow.id,
        adminType: parent.vue.loginInfo.userinfo.adminType,
        menuId: menuId
      }).then(res => {
        if (res.code == 200) {
          this.cancelModelTip = false;
          this.ChangeColumnFn(false);
          this.hadlePageSize({
            page: this.page,
            pageSize: this.pageSize
          });
          this.$Message.success({
            content: "保存成功！",
            background: true
          });
          this.cascadValue = [];
        } else {
          this.$Message.error({
            content: res.desc,
            background: true
          });
        }
      });
    },
    getColumnTree() {
      this.$post(
        "/datamsg/api/pc/information/getChangeColumnMenuTree",
        {
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: this.changeRow.orgCode,
          staffId: parent.vue.loginInfo.userinfo.id,
          adminType: parent.vue.loginInfo.userinfo.adminType,
          businessType: this.changeRow.businessType,
          infoId: this.changeRow.id
        }
        // { "Content-Type": "application/json" }
      ).then(res => {
        if (res.code == 200) {
          let data = res.dataList;
          this.cascaderData = this.forMatterTree(data);
          console.log("递归修改value值后的结构树", this.cascaderData);
        } else {
          this.$Message.error({
            content: "获取数据失败",
            background: true
          });
        }
      });
    },
    // 格式化树结构，给树结构添加上value值和label值
    forMatterTree(data) {
      for (var i = 0; i < data.length; i++) {
        data[i].label = data[i].title;
        data[i].value = data[i].id;
        if (data[i].children.length > 0 || !data[i].children) {
          this.forMatterTree(data[i].children);
        }
      }
      return data;
    },
    //用户操作关闭模态框操作
    UserChooseFn(status) {
      if (!status && this.cancelModelUser) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认要关闭窗口吗？未保存的信息不会保存</p>",
          onOk: () => {
            this.userChooseTle = status;
          }
        });
      } else {
        this.userChooseTle = status;
      }
    },
    // 用户操作记录保存
    userSave(status) {
      this.userChooseTle = status;
      this.isOpenUserMessage = false;
    },
    // 导出按钮
    exportBtn(data){
      this.$post('/datamsg/api/pc/dynamicform/statExportHouseOption',{
        formType:data.id,
        orgCode:data.orgCode
      }).then(res=>{
        if(res && res.code == 200){
          if(res.data){
               if(res.data.slice(0,5) == 'http'){
                 res.data = res.data.replace('http','https')
               }  
          }       
          
          let link = document.createElement("a");
          link.href = res.data;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$Message.destroy();
           this.exportExcelStatus = false
           this.exportExcelTip =false
           this.marital = ''
           this.exportList.id = ''
        }else{
          this.$Message.error({
            content:res.desc,
            background:true
          })
        }
      })
    },
    // 获取搜索级联
    async getMenuParam(){
     await this.$get('/old/api/pc/platform/column/selectChildColumnCode',{
       columnCode:this.menuCodeObj.columnCode,
      //  orgCode:parent.vue.loginInfo.userinfo.orgCode
     }).then(res=>{
       if(res.dataList && res.dataList.length != 0){
          this.meunParamsList = []
         res.dataList.map(item =>{
            this.meunParamsList.push({
              value:item.columnCode,
              label:item.columnName
            })
          })  
       }  
      })
    }
  },
  async created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    // this.buttonRoot = "1003";
    this.status =
      this.buttonRoot == "1001"
        ? "2"
        : this.buttonRoot == "1002"
        ? "1"
        : this.buttonRoot == "1003"
        ? "3"
        : "";
    // 获取functionType/columnCode等
    await this.$get("/old/api/pc/menu/selectColumnByMenuCode", {
      menuCode: this.$core.getUrlParam("menuId")
    }).then(res => {
      if (res.code == 200 && res.data) {
        this.menuCodeObj = res.data;
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    });
    await this.getMenuParam()
    // 处理首页发布——跳审核发布
    if (
      this.$route.query.id &&
      this.$route.query.menuId &&
      this.$route.query.status
    ) {
      switch (this.$route.query.status) {
        case "1":
          this.publishTitle = "审核活动";
          this.infoStatus = "待审核";
          this.publishButtonName = "通过";
          this.publishStatus = true;
          break;
        case "2":
          this.addChangeIden = false;
          this.publishTitle = "修改活动";
          this.changeActivity();
          break;
        case "3":
          this.publishTitle = "发布活动";
          this.infoStatus = "待发布";
          this.publishButtonName = "发布";
          this.publishStatus = true;

          break;
        case "4":
          this.publishTitle = "归档活动";
          this.infoStatus = "已发布";
          this.publishButtonName = "归档";
          this.publishStatus = true;
          break;
      }
      this.activeId = this.$route.query.id;
    }
    // 获取发布范围  releaseScopeList
    // 获取广州市的区
    this.getPulishData(4401);
    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    AddChange,
    Publish,
    AboutLink,
    CheckRecord,
    Analysis,
    contrast
  },
  filters:{
    spliceTitle(option){
      if(option.length >22){
        return option = option.slice(0, 22) + '...'
      }else{
        return option
      }
    }
  }
};
</script>

<style scoped lang='less'>
// /deep/#toolsbarRight {
//   width: 1000%;
//   .search {
//     width: 100%;
//     text-align: right;
//     .ivu-form-item {
//       margin-bottom: 0;
//     }
//   }
// }
/deep/.ivu-modal-close {
  display: none;
}
.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.signInOut {
  /deep/.ivu-modal-close {
    display: block;
  }
}
.qrcode {
  position: relative;
  width: 400px;
  height: 565px;
  background: url("/images/pic_hd.jpg") no-repeat;
  background-size: 100% 100%;
  img {
    position: absolute;
    top: 256px;
    left: 83px;
    width: 230px;
  }
  .h1h3 {
    width: 100%;
    position: absolute;
    top: 75px;
    text-align: center;
    h1{
      width: 70%;
      margin: 0 auto;
      // overflow: hidden;
			// text-overflow: ellipsis;
			// display: -webkit-box;
			// -webkit-line-clamp: 3;
			// -webkit-box-orient: vertical;
    }
  }
  h1 {
    text-align: center;
    font-size: 32px;
    color: #c62e23;
    margin-bottom: 10px;
  }
  h3 {
    padding: 0 30px;
    text-align: center;
    font-size: 26px;
    color: #c62e23;
  }
  p {
    width: 100%;
    position: absolute;
    top: 223px;
    text-align: center;
    font-size: 16px;
    color: #000;
  }
  span {
    width: 70%;
    position: absolute;
    text-align: center;
    top: 488px;
    font-size: 17px;
    color: #000;
    margin: 0 15%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
}
</style>
