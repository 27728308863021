<template>
  <div class="container">
    <!-- 背景图片裁剪弹窗 -->
    <LiefengModal @input="cropperCancel" width="600px" height="500px" :value="cropperStatus">
      <template v-slot:contentarea>
        <Mymodel
            :fullscreen="false"
            :value="toggle1"
            :toggle="true"
            :textObj="textObj"
            title="文本预览"
            :toggle-fn2="toggleFn2"
        >
        </Mymodel>
        <div class="cropper-content" v-if="cropperStatus">
          <div class="cropper" style="text-align:center">
            <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :centerBox="option.centerBox"
                :infoTrue="option.infoTrue"
                :fixedBox="option.fixedBox"
            ></vueCropper>
          </div>
        </div>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" @click="cropperCancel(false)" style="margin-right: 10px">取消</Button>
        <Button type="primary" @click="cropperFinish">裁剪</Button>
      </template>
    </LiefengModal>
    <div class="menu">
      <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
        <MenuItem name="baseInfo">
          <Icon type="ios-information-circle" />
          基本信息
        </MenuItem>
        <MenuItem name="lease">
          <Icon type="ios-create" />
          租赁信息
        </MenuItem>
        <MenuItem name="writeInfo">
          <Icon type="ios-create" />
          填写设置
        </MenuItem>
        <MenuItem name="moreSetting">
          <Icon type="md-chatbubbles" />
          更多设置
        </MenuItem>
      </Menu>
    </div>
    <div class="content">
      <!--基本信息-->
        <Form
        :model="formData"
        :label-colon="true"
        :label-width="120"
        v-show="showMenu == 'baseInfo'"
      >
        <FormItem>
          <span slot="label" class="validate">商品名称:</span>
          <Input style="width:300px" placeholder="请输入商品名称" v-model.trim="formData.title" :maxlength="50"></Input>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">商品编码:</span>
          <Input  style="width:300px" placeholder="请输入商品编码" v-model.trim="formData.goodsInfo.goodsNo" :maxlength="50"></Input>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">公布时间:</span>
          <DatePicker
            :options="dateOption"
            :transfer="true"
            v-model="formData.time"
            type="datetimerange"
            style="width: 400px"
            placeholder="请选择公布开始时间和结束时间"
          ></DatePicker>
          <Tooltip placement="top" :delay="500">
            <Icon type="md-help-circle" style="margin: 10px"></Icon>
            <div slot="content">
              <p>到了公共开始时间，终端上才可查看到；</p>
              <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
            </div>
          </Tooltip>
        </FormItem>
        <FormItem label="联系电话">
          <Input
            v-model.trim="formData.contactMobile"
            style="width: 200px"
            placeholder="请输入联系电话"
          ></Input>
          <span style="color: #aaa; width: 200px; margin-left: 16px"
            >说明：移动端可以直接拨打</span
          >
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">发布终端:</span>
          <CheckboxGroup v-model="formData.terminal" @on-change="allSelect">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV</Checkbox>
            <Checkbox label="3">APP</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
          </CheckboxGroup>

        </FormItem>
        <FormItem label="发布范围">
          <span slot="label" class="validate">发布范围</span>
          <Select
            v-model="city"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择市"
            multiple
            filterable
            @on-change="changeCity"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="region"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择区"
            :disabled="regionDisabled"
            multiple
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="stree"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择街道/乡镇"
            :disabled="streeDisabled"
            multiple
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="project"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择社区"
            :disabled="projectDisabled"
            multiple
            filterable
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="封面图片">
          <span slot="label" class="validate">封面图片:</span>
          <img
            ref="image"
            :src="formData.photo"
            alt=""
            width="200"
            style="display: block; margin-bottom: 10px"
          />
          <Progress
            v-if="file.showProgress"
            :percent="file.percentage"
            :stroke-width="5"
          ></Progress>
          <Upload
          ref="imgUpload"
            :before-upload="beforeUpload"
            :format="['jpg', 'jpeg', 'png', 'gif']"
            style="display: inline-block"
            :on-format-error="imageError"
            :on-progress="imageProgress"
            :on-success="(value, file) => imageSuccess(file)"
            :action="action"
            :data="uploadData"
            :on-remove="imageRemove"
            :show-upload-list="false"
          >
            <Button icon="ios-cloud-upload-outline" type="info">上传图片</Button>
          </Upload>
          <Button v-if="formData.photo" type='success' @click="cropperFn" style="margin-left:10px">裁剪图片</Button>
        </FormItem>
        <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=商品详情（必填）"
          style="width: 100%; height: 500px; border: 0px"
          @load="loadUeditor"
        ></iframe> -->
        <iframe
          name="ueditor"
          id="ueditor"
          src="/mobileeditor/#/index?titlename=商品详情（必填）"
          style="width: 100%; height: 700px; border: 0px"
          @load="loadUeditor"
        ></iframe>
      </Form>
      <!--填写设置-->
      <Form :model="formData" v-show="showMenu == 'writeInfo'">
        <FormItem style="margin: 0 0 12px 10px;display: inline-block"  v-if="addChangeIden">
          <Button
            type="primary"
            @click="openFormTemp"
            >引用模板</Button
          >
        </FormItem>
        <FormItem style="margin: 0 0 12px 10px;display: inline-block">
           <Button type="primary" @click="openOnlyPreview">预览</Button>
        </FormItem>
        <!-- 表单模板 -->
        <div style="margin: 0 10px">
          <AddForm v-if="changeFormShow === false" :hiddenTitle="true" ref="addChangeForm" :formDetailVo2="formDetailVo"></AddForm>
          <ChangeForm v-if="changeFormShow === true" :hiddenTitle="true" :infoId="infoId" :formDetailVo2="formDetailVo" ref="addChangeForm"></ChangeForm>
        </div>
      </Form>
      <!--更多设置-->
      <Form
        :model="formData"
        v-show="showMenu == 'moreSetting'"
        :label-colon="true"
        :label-width="150"
      >
        <FormItem>
          <span slot="label" class="validate">信息来源:</span>
          <Input v-model="formData.source"></Input>
        </FormItem>
        <FormItem label="参与获得积分">
          <InputNumber style="width:200px" :max='999999999' :min="1" v-model="ruleVos[1].ruleValue"></InputNumber>
        </FormItem>
         <FormItem>
          <Checkbox v-model="ruleVos[0].ruleValue">实名认证</Checkbox>
          <span style="color: #aaa;margin-left: 16px">注：勾选后可获填报用户的姓名、性别、年龄、手机号、身份证号，不勾选则只可获得报名用户的手机号</span>
        </FormItem>
        <FormItem label="按钮名称">
          <Select :value="ruleVos[2].ruleValue" style="width:200px" transfer @on-change="changeRuleVos2">
            <Option value="立即租赁">立即租赁</Option>
            <Option value="我要租赁">我要租赁</Option>
            <Option value="自定义按钮名称">自定义按钮名称</Option>
          </Select>
          <span style="color: #aaa;margin-left: 16px">注：用户租赁的时候看到的按钮名称</span>
        </FormItem>
        <FormItem v-if="ruleVos[2].ruleValue == '自定义按钮名称'">
          <span slot="label" class="validate">名称:</span>
          <Input v-model="formData.buttonName" :maxlength="10" placeholder="限10字" style="width:200px"></Input>
        </FormItem> 
      </Form>
      <!--租赁信息-->
      <Form
       :model="formData.goodsInfo"
        v-show="showMenu == 'lease'"
        :label-colon="true"
        :label-width="150">
           <FormItem>
            <span slot="label" class="validate">租赁押金:</span>
             <InputNumber @on-change="changeMortgage" style="width:100px" :max='9999999' :min="0" v-model="formData.goodsInfo.mortgage" ></InputNumber><span style="margin-left:10px">元</span>
          </FormItem>
           <FormItem>
            <span slot="label" class="validate">租赁扣费:</span>
            <InputNumber  @on-change="changeLeasePrice" style="width:100px" :max='9999999' :min="0"  v-model="formData.goodsInfo.leasePrice"></InputNumber><span style="margin-left:10px">元</span>
            <Select :value="formData.goodsInfo.leaseModule" style="width:100px;margin-left:10px" transfer @on-change="changeLease">
                <Option value="1">/天</Option>
                <Option value="2">/时</Option>
              </Select>
          </FormItem>
           <FormItem>
            <span slot="label">线下库存:</span>
            <InputNumber @on-change="changeGoods" style="width:100px" placeholder="请输入线下库存" :max='99999999' :min="0" v-model="formData.goodsInfo.goodsNum"></InputNumber><span style="margin-left:10px">件</span>
          </FormItem>
           <FormItem>
            <span slot="label"  class="validate">启用状态:</span>
             <Select :value="formData.goodsInfo.status" style="width:200px" transfer @on-change="changeStatus">
                <Option value="1">上架</Option>
                <Option value="2">下架</Option>
              </Select>
          </FormItem>
      </Form>
      <Form
        :model="formData"
        v-show="showMenu == 'lease'"
        :label-colon="true"
        :label-width="150">

         <FormItem>
              <span slot="label" class="validate">商品分类:</span>
              <Select :value="formData.subType" style="width:200px" transfer @on-change="changeSubType">
                <Option v-for="(item,index) in meunParamsList" :key="index" :value="item.value">{{item.label}}</Option>
              </Select>
            </FormItem>
         <FormItem>
              <span slot="label" class="validate">是否推荐:</span>
              <RadioGroup v-model="formData.recommend" @on-change="changeRecommend">
                <Radio label="1">推荐</Radio>
                <Radio label="0">不推荐</Radio>
              </RadioGroup>
            </FormItem>
        <FormItem>
          <span slot="label">推送方式:</span>
          <Checkbox v-model="formData.pushChannel">小程序订阅通知</Checkbox>
        </FormItem>
      </Form>
    </div>
  <LiefengModal
    :value="formTemplateStatus"
    @input="formTemplateStatusFn"
    :fullscreen="true"
    title="模板库"
  >
    <template v-slot:contentarea>
        <Tabs type="card" @on-click="toggleTab" class="tempContainer">
          <TabPane label="全部模板">
              <div class="tempBox">
                 <span v-if="formTemplateDate.length == 0" class="noList">暂无模板，请先添加</span>
               <Card v-for="(item,index) in formTemplateDate" :key="index" v-else>
                <p slot="title">{{item.formName}}</p>
                <p>创建：{{item.createName}}</p>
                <p>创建时间：{{item.gmtCreate}}</p>
                <p>共{{item.fieldNum}}题</p>
                <div style="text-align:right">
                  <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                  <Button type="success" @click="previewForm(item.id)">预览</Button>
                </div>
            </Card>
            </div>
            <Page style="float:right;margin-right:8px" :total="total" show-total @on-change="changePage"/>
            </TabPane>
          <TabPane label="个人模板">
            <div class="tempBox">
              <span v-if="formTemplateDate.length == 0" class="noList"><i>暂无模板，请先添加</i></span>
               <Card v-for="(item,index) in formTemplateDate" :key="index" v-else>
                <p slot="title">{{item.formName}}</p>
                <p>创建：{{item.createName}}</p>
                <p>创建时间：{{item.gmtCreate}}</p>
                <p>共{{item.fieldNum}}题</p>
                <div style="text-align:right">
                  <Button style="margin-right: 10px" type="primary" @click="recommend(item.id)">引用</Button>
                  <Button type="success" @click="previewForm(item.id)">预览</Button>
                </div>
            </Card>
            </div>
            <Page style="float:right;margin-right:8px" :total="total" show-total @on-change="changePage"/>
            </TabPane>
          
        </Tabs>
    </template>
  </LiefengModal>
   <!-- 查看预览 -->
      <LiefengModal
        :title="previeswTitle"
        :fullscreen="true"
        :value="previewStatus"
        @input="previewStatusFn"
      >
        <template v-slot:contentarea>
          <PreviewForm
            :previewFormData="previewFormData"
            v-if="previewStatus"
          ></PreviewForm>
        </template>
        <template v-slot:toolsbar>
          <Button type="primary" @click="previewStatus = false">关闭</Button>
        </template>
      </LiefengModal>
      <!-- 编辑预览 -->
      <!-- 编辑表单预览 -->
    <LiefengModal
      title="表单预览"
      :fullscreen="true"
      :value="onlyPreviewStatus"
      @input="onlyPreviewStatusFn"
    >
      <template v-slot:contentarea>
        <PreviewForm
          :onlyPreview="true"
          :activeMainList="activeMainList"
          v-if="onlyPreviewStatus"
        ></PreviewForm>
      </template>
      <template v-slot:toolsbar>
        <Button type="primary" @click="onlyPreviewStatus = false">关闭</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengEditor from "@/components/LiefengEditor";
import AddForm from "@/views/form/childrens/addForm";
import ChangeForm from "@/views/form/childrens/changeForm";
import LiefengModal from "@/components/LiefengModal";
import PreviewForm from "@/views/form/childrens/previewForm";
import {VueCropper} from "vue-cropper";
import Mymodel from "@/views/activity/Mymodel";

export default {
  props: ["menuCodeObj", "activeId", "addChangeIden"],
  data() {
    return {
      //富文本预览
      toggle1: false, //
      textObj: {
        spinShow: false,
        signInUrl: '', //预览二维码url
      },
      // 裁剪组件的基础配置option
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: "jpg", // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 600, // 默认生成截图框宽度
        autoCropHeight: 500, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [100, 69], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      cropperStatus: false,

      showMenu: "baseInfo",
      formData: {
        terminal: ["0","1", "3", "4"],
        time: [],
        source: parent.vue.loginInfo.userinfo.orgName,
        recommend: '0',  //是否推荐,
        pushChannel: false, //小程序推送通知
        subType:'',
        goodsInfo:{
          leaseModule:'1',
          mortgage:'',
          leasePrice:'',
          status:'1',
          goodsNum:'10'
        }
      }, //新增修改时提交的数据第一层
      cycleDates: [], //重复内容--日期 / 周
      cycleDateList: (() => {
        let arr = [];
        for (let i = 1; i < 32; i++) {
          arr.push({
            value: i,
            label: i,
          });
        }
        return arr;
      })(),
      cycleTimeList: [
        { value: 1, label: "周一" },
        { value: 2, label: "周二" },
        { value: 3, label: "周三" },
        { value: 4, label: "周四" },
        { value: 5, label: "周五" },
        { value: 6, label: "周六" },
        { value: 7, label: "周日" },
      ],
      cycleTimes: [
        {
          limits: [
            {
              attrType: "person",
              attrValue: "",
            },
          ],
          timeValue: [],
        },
      ], //开展时间
      dateOption: {
        shortcuts: [
          //公布时间配置项
          {
            text: "1天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
              return [start, end];
            },
          },
          {
            text: "7天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "30天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "365天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
              return [start, end];
            },
          },
        ],
      },
      // 发布终端临时变量
      terminalList: [],
      dataScopeIdList: [], //发布范围列表
      city: [],
      region: [],
      stree: [],
      project: [],
      cityList: [],
      regionList: [],
      streeList: [],
      projectList: [],

      regionDisabled: true,
      streeDisabled: true,
      projectDisabled: true,
      // 上传图片
      action: "",
      file: "",
      imageName: "",
      imagePath: "",
      uploadData: {},
      ruleVos: [
        //规则
        {
          ruleCode: "authentication", //实名认证
          ruleValue: true,
        },
        {
          ruleCode: 'participate_integral"', //参与获得积分
          ruleValue: 1,
        },
        {
          ruleCode: "button_name", //按钮名称
          ruleValue: "立即租赁",
        },
        {
          ruleCode: "whether_registration", //代报名
          ruleValue: "0",
        },
        {
          ruleCode: "registration_type", //代报填写信息
          ruleValue: "",
        },
        {
          ruleCode: "signature", //填报用户签名确认
          ruleValue: "0",
        },
        {
          ruleCode: "submit_only", //限制每人限填一次
          ruleValue: "0",
        },
      ],
      // 模板弹窗
      formTemplateStatus: false,
      formTemplateDate: [],
      page: 1,
      pageSize: 9,
      total: 0,
      tabName: 0,
      changeFormShow: false, //控制显示新增还是修改表单组件
      infoId: "",
      formDetailVo: {}, //切换菜单时暂存模板表单数据

      // 预览
      previewStatus: false,
      previewFormData: {},
      previeswTitle: "",

      // 保存校验必填项
      validateArr: [
        {
          text: "基本信息———商品不能为空",
          value: "title",
        },
        {
          text: "基本信息———商品编码不能为空",
          value: "goodsNo",
        },
        {
          text: "基本信息———公布时间不能为空",
          value: "time",
        },
        {
          text: "基本信息———请选择发布终端",
          value: "terminal",
        },
        {
          text: "基本信息———请选择发布范围",
          value: "city",
        },
        {
          text: "基本信息———请上传封面图片",
          value: "photo",
        },
        {
          text: "基本信息———商品详情不能为空",
          value: "content",
        },
        {
          text: "租赁信息———租赁押金不能为空",
          value:"mortgage"
        },
        {
          text: "租赁信息———租赁扣费不能为空",
          value:"leasePrice"
        },
        {
          text: "租赁信息———租赁扣费单位不能为空",
          value:"leaseModule"
        },
        {
          text: "租赁信息———启用状态不能为空",
          value:"status"
        },
        {
          text: "租赁信息———商品分类不能为空",
          value:"subType"
        },
        {
          text: "信息来源———请填写信息来源",
          value: "source",
        },
        
      ],

      // 编辑预览
      activeMainList: [],
      onlyPreviewStatus: false,

      // 修改时若需签名对象，
      signatureObj: {},

      // 商品分类
      meunParamsList:[]
    };
  },
  methods: {
    // 限制押金只能输入最小0.01
    changeMortgage(value){
        let data = ''
        if(value && value != '' && String(value).indexOf('.') > -1){ 
         data = String(value).substring(0,String(value).indexOf('.')+3)   
          this.$nextTick(()=>{
            this.formData.goodsInfo.mortgage = data
          })
        }
        
    },
    changeLeasePrice(value){
      let data = ''
        if(value && value != '' && String(value).indexOf('.') > -1){ 
         data = String(value).substring(0,String(value).indexOf('.')+3)
         this.$nextTick(()=>{
           this.formData.goodsInfo.leasePrice = data
        })
        }
        
    },
    // 库存
    changeGoods(val){
      if(/\D/g.test(val)){
        this.formData.goodsInfo.goodsNum = 0
      }
      
    },
    // 商品分类
    changeSubType(val){
      console.log(val);
      this.formData.subType = val
    },
    // 启用状态
    changeStatus(val){
      console.log('状态的val',val);
      this.formData.goodsInfo.status = val
    },
    // 租赁单位
    changeLease(val){
      this.formData.goodsInfo.leaseModule = val
    },
    // 是否推荐改变。别的也变
    changeRecommend(val){
      this.formData.recommend = val
    },
    toggleFn2() {
      this.toggle1 = false
    },
    // 表单编辑时预览
    openOnlyPreview() {
      this.activeMainList = JSON.parse(JSON.stringify(this.$refs.addChangeForm.mainList));
      this.onlyPreviewStatus = true;
    },
    onlyPreviewStatusFn(status) {
      this.onlyPreviewStatus = status;
    },


    changeRuleVos2(val) {
      this.ruleVos.splice(2, 1, {
        ruleCode: "button_name", //按钮名称
        ruleValue: val,
      });
    },
    changeruleVos3(val) {
      this.ruleVos.splice(3, 1, {
        ruleCode: "registration_type", //代报填写信息
        ruleValue: val,
      });
    },
    changeruleVos4(val) {
      this.ruleVos.splice(5, 1, {
        ruleCode: "signature", //填报用户签名确认
        ruleValue: val,
      });
    },
    changeruleVos5(val) {
      this.ruleVos.splice(6, 1, {
        ruleCode: "submit_only", //限制每人限填一次
        ruleValue: val,
      });
    },
    loadUeditor() {
      if (
          this.formData.content != "" &&
          this.formData.content != null &&
          this.formData.content != undefined
      ) {
        var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
        v_Obj.setContent(this.formData.content); //写入编辑器富文本内容
      } else {
        setTimeout(this.loadUeditor, 300);
      }
    },
    // 保存校验
    validateFn() {
      for (let k in this.validateArr) {
        if (
          this.validateArr[k].value != "time" &&
          this.validateArr[k].value != "terminal" &&
          this.validateArr[k].value != "city" &&
          this.validateArr[k].value != "cycleDates" &&
          this.validateArr[k].value != "cycleTimes" &&
          this.validateArr[k].value != "whether_registration" &&
          this.validateArr[k].value != "registration_type" &&
          this.validateArr[k].value != "goodsNo" &&
          this.validateArr[k].value != "mortgage" &&
          this.validateArr[k].value != "leasePrice" &&
          this.validateArr[k].value != "leaseModule" &&
          this.validateArr[k].value != "status" &&
          (this.formData[this.validateArr[k].value] == "" ||
            this.formData[this.validateArr[k].value] == undefined)
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if((this.validateArr[k].value == 'goodsNo' || this.validateArr[k].value == 'mortgage' || this.validateArr[k].value == 'leasePrice' || this.validateArr[k].value == 'leaseModule' || this.validateArr[k].value == 'status') && (this.formData.goodsInfo[this.validateArr[k].value] == undefined || this.formData.goodsInfo[this.validateArr[k].value] == '')){
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "time" &&
          typeof this.formData[this.validateArr[k].value][0] == "string"
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "terminal" &&
          this.formData.terminal.length == 0
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "city" &&
          this[this.validateArr[k].value].length == 0
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "cycleDates" &&
          this.cycleDates.length == 0
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "cycleTimes" &&
          this.cycleTimes[0].timeValue[0] == ""
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "whether_registration" &&
          this.ruleVos[3].ruleValue == ""
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        if (
          this.validateArr[k].value == "registration_type" &&
          this.ruleVos[4].ruleValue == "" &&
          this.ruleVos[3].ruleValue == "1"
        ) {
          this.$Message["error"]({
            background: true,
            content: this.validateArr[k].text,
          });
          return false;
        }
        
      }
      return true;
    },
    contentFn() {   //富文本预览
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formData.content = v_Obj.getContent(); //获取富文本内容
      console.log(this.formData.content, '内容')

      if (!this.formData.content) {
        this.$Message.warning({
          background: true,
          content: '暂没内容，点击编辑吧！',
          duration: 2
        });
        return
      }

      this.$Message.loading({
        background: true,
        content: '正在加载，请稍等...'
      });


      let params = {
        content: this.formData.content
      }
      this.$post(
          "/datamsg/api/pc/wxqr/createWxAppletQr",
          {
            type: "RICH_TEXT_PREVIEW_PAGE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath:
                  "/indexPages/previewRichText/index",
              params: {
                ...params,
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        console.log(res, 'res')
        if (res.code == 200 && res.data) {
          this.textObj.signInUrl = res.data.codeUrl;
          this.toggle1 = true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    save() {
      if(this.formData.pushChannel == true){
        this.$Modal.confirm({
          title: "退出提示",
          content: "<p>当前发布包括推送，是否继续？</p>",
          onOk: () => {
              this.addPush()
          }
          })
      }else{
            this.addPush()
      }
    },
    addPush(){
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
            this.formData.content = v_Obj.getContent(); //获取富文本内容
            this.formData.pushChannel = this.formData.pushChannel === false ? '' : '4'
            // 校验必填项
            if (!this.validateFn()) {
              return;
            }

            
            this.$Message.loading({
              content: "正在保存数据，请稍等...",
              duration: 0,
            });
            this.formDetailVo = this.$refs.addChangeForm.exportFormData();

           if(this.formDetailVo.interactionFormVos.length == 0){
              this.$Message.destroy();
              this.$Message.error({
                content:'填写设置--填写信息不能为空',
                background:true
              })
              return
           }
    
            // zhw start

          if(Object.keys(this.formDetailVo).length != 0 && this.formDetailVo.interactionFormVos.length != 0){
            this.formDetailVo.interactionFormVos.map(item =>{
              if(item.fieldParamVos.length != 0){
                let obj = {}
                let isTrue = false
                item.fieldParamVos.map(items =>{
                  if(items.paramCode != "fieldType"){
                      switch(item.formType){
                          case 'userName':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'姓名',value:'userName'}
                          isTrue = true
                          break;
                          case 'mobile':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'手机号',value:'mobile'}
                          isTrue = true
                          break;
                          case 'idNum':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'身份证号',value:'idNum'}
                          isTrue = true
                          break;
                          case 'addr':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'地址',value:'addr'}
                          isTrue = true
                          break;
                          case 'sex':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'性别',value:'sex'}
                          isTrue = true
                          break;
                          case 'age':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'年龄',value:'age'}
                          isTrue = true
                          break;
                          case 'marital':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'婚姻状况',value:'marital'}
                          isTrue = true
                          break;
                          case 'workUnits':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'工作单位',value:'workUnits'}
                          isTrue = true
                          break;
                          case 'national':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'民族',value:'national'}
                          isTrue = true
                          break;
                          case 'brithday':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'出生日期',value:'brithday'}
                          isTrue = true
                          break;
                          case 'political':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'政治面貌',value:'political'}
                          isTrue = true
                          break;
                          case 'vaccines':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'新冠疫苗接种情况',value:'vaccines'}
                          isTrue = true
                          break;
                          case 'brand':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'接种品牌',value:'brand'}
                          isTrue = true
                          break;
                          case 'testing':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'核酸检测时间',value:'testing'}
                          isTrue = true
                          break;
                          case 'result':
                          obj = {fieldId: this.$core.randomString(),id: this.$core.randomString(),paramCode:'fieldType',paramName:'核酸检查结果',value:'result'}
                          isTrue = true
                          break;
                        }
                  }
                })
                
                if(isTrue){
                  item.fieldParamVos.push(obj)
                }
                
              }
              
            })
          
          }

          if(String(this.formData.goodsInfo.mortgage).indexOf('.') > -1) {
            if(String(this.formData.goodsInfo.mortgage).split('.')[1].length == 1){
              this.formData.goodsInfo.mortgage = this.formData.goodsInfo.mortgage + '0'
            }
            if(String(this.formData.goodsInfo.mortgage).split('.')[1].length == 0){
              this.formData.goodsInfo.mortgage = this.formData.goodsInfo.mortgage + '00'
            }
          }
          else{
            this.formData.goodsInfo.mortgage = this.formData.goodsInfo.mortgage + '.00'
          }
          if(String(this.formData.goodsInfo.leasePrice).indexOf('.') > -1) {
             if(String(this.formData.goodsInfo.leasePrice).split('.')[1].length == 1){
              this.formData.goodsInfo.leasePrice = this.formData.goodsInfo.leasePrice + '0'
            }
            if(String(this.formData.goodsInfo.leasePrice).split('.')[1].length == 0){
              this.formData.goodsInfo.leasePrice = this.formData.goodsInfo.leasePrice + '00'
            }
          }else{
            this.formData.goodsInfo.leasePrice = this.formData.goodsInfo.leasePrice + '.00'
          }

          if(this.formData.goodsInfo.goodsNum == 0){
            this.formData.goodsInfo.status = 2
          }
          // console.log('之前的',this.formData.goodsInfo.mortgage);
          // console.log('之后的',parseFloat(this.formData.goodsInfo.mortgage));
          // console.log('之前的',this.formData.goodsInfo.leasePrice);
          // console.log('之后的',parseFloat(this.formData.goodsInfo.leasePrice));
          // return
          
       
      // zhw end

            let url = "";
            let params = {};   
            if (this.addChangeIden) {
              url = "/datamsg/api/pc/information/goodsLease/create";
              if(this.ruleVos[5].ruleValue == '1') {  //代表需要签名，此时新增一条签名的题目
              this.formDetailVo.interactionFormVos.push({
                authentication: '',
                content: 'signature',
                fieldCode: 'signature',
                fieldId: this.$core.randomString(),
                fieldParamVos: [],
                formType: 'signature',
                list: [],
                relevance: "",
                required: '1',
                sequence: this.formDetailVo.interactionFormVos.length,
                topic: '填报用户签名',
                classField: '',
                tbField: '',
              })
            }
            } else {
              url = "/datamsg/api/pc/information/goodsLease/update";
              params = {
                id: this.activeId,
              };
              if(this.ruleVos[5].ruleValue == '1') {  //代表需要签名，此时新增一条签名的题目
              if(JSON.stringify(this.signatureObj) === '{}') {
                this.formDetailVo.interactionFormVos.push({
                authentication: '',
                content: 'signature',
                fieldCode: 'signature',
                fieldId: this.$core.randomString(),
                fieldParamVos: [],
                formType: 'signature',
                list: [],
                relevance: "",
                required: '1',
                sequence: this.formDetailVo.interactionFormVos.length,
                topic: '填报用户签名',
                classField: '',
                tbField: '',
              })
              }else {
                this.formDetailVo.interactionFormVos.push(this.signatureObj);
              }

            }
            }
            this.$post(
              url,
                {
                  ...this.formData,
                  ...params,
                  photo:
                      this.formData.photo.indexOf("?") != "-1"
                          ? this.formData.photo.substring(
                          0,
                          this.formData.photo.indexOf("?")
                          )
                          : this.formData.photo,
                  startTime: this.$core.formatDate(new Date(this.formData.time[0]), 'yyyy-MM-dd hh:mm:ss'),
                  endTime: this.$core.formatDate(new Date(this.formData.time[1]), 'yyyy-MM-dd hh:mm:ss'),
                terminal: this.formData.terminal.indexOf('0') != -1 ? '0' : this.formData.terminal.join(","),
                dataScopeIdList: (() => {
                  if (this.project.length > 0) {
                    return this.project;
                  } else if (this.stree.length > 0) {
                    return this.stree;
                  } else if (this.region.length > 0) {
                    return this.region = typeof this.region == 'string' ? [this.region] : this.region;
                  } else {
                    return typeof this.city == "string"
                      ? this.city.split(",")
                      : this.city;
                  }
                })(),
                formDetailVo: this.formDetailVo,
                status: "1",
                // recommend: "0",
                sort: this.formData.sort || '100',
                time: null,
                columnCode: this.menuCodeObj.columnCode,
                ruleVos: [
                  {
                    ruleCode: "authentication",
                    ruleValue: this.ruleVos[0].ruleValue,
                  },
                  {
                    ruleCode: "participate_integral",
                    ruleValue: this.ruleVos[1].ruleValue,
                  },
                  {
                    ruleCode: "button_name",
                    ruleValue:
                        this.ruleVos[2].ruleValue == "自定义按钮名称"
                            ? this.formData.buttonName
                            : this.ruleVos[2].ruleValue,
                  },
                  {
                    ruleCode: "whether_registration",
                    ruleValue: this.ruleVos[3].ruleValue,
                  },
                  {
                    ruleCode: "registration_type",
                    ruleValue: this.ruleVos[4].ruleValue,
                  },
                  {
                    ruleCode: "signature", //填报用户签名确认
                    ruleValue: this.ruleVos[5].ruleValue,
                  },
                  {
                    ruleCode: "submit_only", //限制每人限填一次
                    ruleValue: this.ruleVos[6].ruleValue,
                  },
                ],
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operator: {
                  businessType: this.menuCodeObj.businessType,
                  functionType: this.menuCodeObj.functionType,
                  oemCode: parent.vue.oemInfo.oemCode,
                  operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                  orgCode: parent.vue.loginInfo.userinfo.orgCode,
                  terminal: this.formData.terminal.indexOf('0') != -1 ? '0' : this.formData.terminal.join(","),
                },
              },
              { "Content-Type": "application/json" }
            )
              .then((res) => {
                this.$Message.destroy();
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "保存成功",
                  });
                  this.$emit("saveSuccess");
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                this.$Message.destroy();
                this.$Message.error({
                  background: true,
                  content: "保存失败，请重试",
                });
              });
    },
    // 模板库
    formTemplateStatusFn(status) {
      this.formTemplateStatus = status;
    },
    changePage(size) {
      this.handleTableData({
        page: size,
        pageSize: this.pageSize,
      });
    },
    // 引用
    recommend(id) {
      this.$Message.loading({
        content: "正在加载模板数据，请稍等...",
        duration: 0,
      });
      this.infoId = "";
      this.$get("/datamsg/api/pc/dynamicform/copyFormDetail", {
        formId: id,
      }).then(res => {
         if (res.code == 200 && res.data) {
            this.formDetailVo = res.data;
            this.changeFormShow = "";
            this.$nextTick(() => {
              this.changeFormShow = true;
            });
            this.$get('/datamsg/api/pc/dynamicform/citations',{
              formId: id
            })
            this.$Message.destroy();
            this.formTemplateStatus = false;
          } else {
            this.$Message.destroy();
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
      }).catch(err => {
        console.log(err);
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "获取数据失败，请联系管理员处理"
        })
      })
    },
    // 预览
    previewStatusFn(status) {
      this.previewStatus = status;
    },
    previewForm(id) {
      this.$Message.loading("正在加载数据,请稍等...");
      this.$get("/datamsg/api/pc/dynamicform/selectFormDetail", {
        formId: id,
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.data) {
          this.previewFormData = res.data;
          this.previeswTitle = res.data.formTitle;
          this.previewStatus = true;
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    // tab切换
    toggleTab(number) {
      this.tabName = number;
      this.handleTableData({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    handleTableData(obj) {
      this.$get("/datamsg/api/pc/dynamicform/selectFormPage", {
        orgCode: this.tabName == 0 ?'': parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        custGlobalId: this.tabName == 0 ? '' : parent.vue.loginInfo.userinfo.custGlobalId,
        templateType: this.tabName == 0 ? "2" : "1", //1：私有；2：公开
      }).then((res) => {
        this.$Message.destroy();
        if (res.code == 200 && res.dataList) {
          res.dataList.map((item) => {
            item.gmtCreate = this.$core.formatDate(
              new Date(item.gmtCreate),
              "yyyy-MM-dd hh:mm:ss"
            );
          });
          this.formTemplateDate = res.dataList;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;
          if (!this.formTemplateStatus) this.formTemplateStatus = true; //代表打开弹窗
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }).catch(err => {
        console.log(err);
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "获取数据失败，请联系管理员处理"
        })
      })
    },
    openFormTemp(obj) {
      this.$Message.loading({
        content: "正在加载模板数据，请稍等...",
        duration: 0,
      });
      this.handleTableData({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    // 切换菜单
    selectMenu(name) {
      this.showMenu = name;
    },
    // 发布终端全选
    allSelect(arr) {
      if (this.terminalList.length == 6 && arr.indexOf("0") == -1) {
        this.formData.terminal = [];
      } else if (this.terminalList.length == 6 && arr.indexOf("0") > -1) {
        arr.splice(arr.indexOf("0"), 1);
        this.formData.terminal = arr;
      } else if (arr.length == 5 && arr.indexOf("0") == -1) {
        this.formData.terminal = ["0", "1", "2", "3", "4", "5"];
      } else if (arr.indexOf("0") > -1 && arr.length < 6) {
        this.formData.terminal = ["0", "1", "2", "3", "4", "5"];
      }
      this.terminalList = JSON.parse(JSON.stringify(this.formData.terminal));
    },
    // 添加开展时段
    addCycleTimes() {
      this.cycleTimes.push({
        limits: [
          {
            attrType: "person",
            attrValue: "",
          },
        ],
        timeValue: [],
      });
    },
    //取消裁剪
    cropperCancel(status) {
      this.cropperStatus = status;
    },

    //图片裁剪
    async cropperFn() {
      if (!this.uploadData.host) {
        await this.$get(
          "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
        ).then((res) => {
          if (res.code == 200) {
            let now = this.$core.randomNumber();
            //防止文件名有.
            let suffixArr = this.formData.photo.split(".");
            let suffix = "." + suffixArr[suffixArr.length - 1];
            this.action = window.vue.getProxy()["/oss"].target;
            this.uploadData = {
              name: now + suffix,
              host: res.data.host,
              key: res.data.key + now + suffix,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
            this.imageName = now + suffix;
            this.imagePath = this.uploadData.host + this.uploadData.key;
          }
        });
      }

      let _this = this;
      this.setAvatarBase64(this.formData.photo, (base64) => {
        _this.option.img = base64;
      });
      this.cropperStatus = true;
    },
    // 设置图片base64
    setAvatarBase64(src, callback) {
      let _this = this;
      let image = new Image();
      // 处理缓存
      image.src = src + "?v=" + Math.random();
      // 支持跨域图片
      image.crossOrigin = "*";
      image.onload = function () {
        let base64 = _this.transBase64FromImage(image);
        callback && callback(base64);
      };
    },
    // 将网络图片转换成base64格式
    transBase64FromImage(image) {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      // 可选其他值 image/jpeg
      return canvas.toDataURL("image/png");
    },
    //确定裁剪
    cropperFinish() {
      this.$refs.cropper.getCropBlob((data) => {
        this.$refs.imgUpload.clearFiles();
        let formData = new FormData();
        formData.append("name", this.uploadData.name);
        formData.append("host", this.uploadData.host);
        formData.append("key", this.uploadData.key);
        formData.append("policy", this.uploadData.policy);
        formData.append("OSSAccessKeyId", this.uploadData.OSSAccessKeyId);
        formData.append(
          "success_action_status",
          this.uploadData.success_action_status
        );
        formData.append("signature", this.uploadData.signature);
        formData.append("file", data);
        this.$post("https://liefeng.oss-cn-shenzhen.aliyuncs.com", formData, {
          "Content-Type": "multipart/form-data",
        }).then((res) => {
          this.$Message.success({
            background: true,
            content: "裁剪完成",
          });
          this.formData.photo = "";
          this.$nextTick(() => {
            this.formData.photo = this.imagePath + "?" + new Date().getTime();
            this.cropperCancel(false);
          });
        });
      });
    },

    // 上传之前
    beforeUpload(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = this.$core.randomNumber();
          //防止文件名有.
          let suffixArr = file.name.split(".");
          let suffix = "." + suffixArr[suffixArr.length - 1];
          this.action = window.vue.getProxy()["/oss"].target;
          this.uploadData = {
            name: now + suffix,
            host: res.data.host,
            key: res.data.key + now + suffix,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.imageName = now + suffix;
          this.imagePath = res.data.host + res.data.key + this.imageName;
        }
      });
    },
    //图片上传成功
    imageSuccess(file) {
      if (file.status == "finished") {
        this.formData.photo = this.imagePath;
        this.$Message["success"]({
          background: true,
          content: "图片上传成功！",
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "图片上传失败！",
        });
      }
    },
    // 图片格式错误
    imageError(file) {
      this.$Notice.warning({
        title: "上传文件格式错误！",
        desc:
          "文件 " +
          file.name +
          " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
      });
    },
    // 图片上传进度
    imageProgress(event, file, fileList) {
      this.file = file;
      event.target.onprogress = (event) => {
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        ); // 保留两位小数
        // 手动设置显示上传进度条 以及上传百分比
        this.file.showProgress = true;
        this.file.percentage = uploadPercent;
      };
    },
    // 图片移除
    imageRemove(file) {
      this.formData.photo = "";
      this.file = {};
    },
    // 城市选择
    changeCity(code) {
      if (code.length >= 2) {
        this.regionList = [];
        this.streeList = [];
        this.projectList = [];
        this.region = [];
        this.stree = [];
        this.project = [];
        this.regionDisabled = true;
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.regionDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }
    },
    // 区选择
    changeRegion(code) {
      if (code.length >= 2) {
        this.streeList = [];
        this.projectList = [];
        this.stree = [];
        this.project = [];
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.streeDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }
    },
    // 街道选择
    changeStree(code) {
      if (code.length >= 2) {
        this.projectList = [];
        this.project = [];
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.projectDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }
    },
    // 获取发布范围列表
    getPulishData(code, list, model) {
      this.$get("/datamsg/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
            };
          });
          if (res.dataList.length == "1") {
            this[model] = [res.dataList[0].orgPath];
          }
        }
      });
    },
    // 获取搜索级联
    async getMenuParam(){
     await this.$get('/old/api/pc/platform/column/selectChildColumnCode',{
       columnCode:this.menuCodeObj.columnCode,
      //  orgCode:parent.vue.loginInfo.userinfo.orgCode
     }).then(res=>{
           this.meunParamsList = []
         res.dataList.map(item =>{
            this.meunParamsList.push({
              value:item.columnCode,
              label:item.columnName
            })
          })  
      })
    }
  },
  async created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    await this.getPulishData("44", "cityList", "city");
    await this.getMenuParam()
    if (this.activeId) {
      await this.$get("/datamsg/api/pc/information/v2/queryById", {
        infoId: this.activeId,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        businessType: this.menuCodeObj.businessType,
        functionType: this.menuCodeObj.functionType,
        terminal: "2",
      }).then((res) => {
        if (res.code == 200 && res.data) {
          this.$Message.destroy();
          if (res.data.dataScopeList && res.data.dataScopeList.length == 1) {
            //回显发布范围
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city = item;
                  break;
                case 3:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = item;
                  break;
                case 4:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree = item;
                  break;
                case 5:
                  this.city = arr[0] + "-" + arr[1];
                  this.region = arr[0] + "-" + arr[1] + "-" + arr[2];
                  this.stree =
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3];
                  this.project = item;
                  break;
              }
            });
          } else if (
            res.data.dataScopeList &&
            res.data.dataScopeList.length > 1
          ) {
            res.data.dataScopeList.map((item) => {
              let arr = item.split("-");
              switch (arr.length) {
                case 2:
                  this.city.push(item);
                  break;
                case 3:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(item);
                  break;
                case 4:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(item);
                  break;
                case 5:
                  this.city.push(arr[0] + "-" + arr[1]);
                  this.region.push(arr[0] + "-" + arr[1] + "-" + arr[2]);
                  this.stree.push(
                    arr[0] + "-" + arr[1] + "-" + arr[2] + "-" + arr[3]
                  );
                  this.project.push(item);
                  break;
              }
            });
            this.city = Array.from(new Set(this.city));
            this.region = Array.from(new Set(this.region));
            this.stree = Array.from(new Set(this.stree));
            this.project = Array.from(new Set(this.project));
          }
          this.formData = {
            title: res.data.title,
            recommend: res.data.recommend,
            pushChannel: res.data.pushChannel === '4' ? true : false, //小程序订阅通知
            time: [new Date(res.data.startTime), new Date(res.data.endTime)],
            contactMobile: res.data.contactMobile,
            terminal: res.data.terminal.split(',').indexOf('0') != -1 ? ['0', '1', '2', '3', '4', '5'] : res.data.terminal.split(','),
            photo: res.data.photo,
            content: res.data.content,
            source: res.data.source,
            goodsInfo:res.data.goodsInfo,
            subType:res.data.subType,
            sort:res.data.sort
          };
          if (res.data.formDetailVo) {
            this.formDetailVo = res.data.formDetailVo;
            this.changeFormShow = true; // 切换为表单修改组件
          }
          if (res.data.ruleVos) {
            res.data.ruleVos.map((item) => {
              console.log(item,'item')
              if (item.ruleCode == "authentication") {
                this.ruleVos[0] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue == "true" ? true : false,
                };
              } else if (item.ruleCode == "participate_integral") {
                this.ruleVos[1] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue ? Number(item.ruleValue) : "",
                };
              } else if (item.ruleCode == "button_name") {
                if (
                  item.ruleValue == "立即租赁" ||
                  item.ruleValue == "我要租赁" 
                ) {
                  this.ruleVos[2] = {
                    ruleCode: item.ruleCode,
                    ruleValue: item.ruleValue,
                  };
                }else {
                   this.ruleVos[2] = {
                  ruleCode: item.ruleCode,
                  ruleValue: '自定义按钮名称',
                };
                this.formData.buttonName = item.ruleValue;
                }
              } else if (item.ruleCode == "whether_registration") {
                this.ruleVos[3] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              } else if (item.ruleCode == "registration_type") {
                this.ruleVos[4] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              } else if (item.ruleCode == "signature") {
                this.ruleVos[5] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
                if (item.ruleValue == '1' && this.formDetailVo.interactionFormVos) {  //需要签名
                  let index = this.formDetailVo.interactionFormVos.findIndex(res => res.content == 'signature');
                  let signatureObj = this.formDetailVo.interactionFormVos.splice(index, 1);
                  if (signatureObj && signatureObj.length > 0) {
                    this.signatureObj = signatureObj[0];
                  }
                }
              } else if (item.ruleCode == "submit_only") {
                this.ruleVos[6] = {
                  ruleCode: item.ruleCode,
                  ruleValue: item.ruleValue,
                };
              }
            });
          }
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      }).catch(err => {
        this.$Message.destroy();
        console.log(err);
        this.$Message.error({
          background: true,
          content: "获取数据失败，请联系管理员处理"
        })
      })
    }
  },
  components: {
    LiefengEditor,
    AddForm,
    LiefengModal,
    ChangeForm,
    PreviewForm,
    VueCropper,
    Mymodel
  },
};
</script>

<style scoped lang='less'>
// 截图
.cropper-content {
  .cropper {
    width: 580px;
    height: 428px;
  }
}
.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 123px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    overflow: auto;
    flex: 1;
    height: 100%;
  }
}
/deep/.ivu-tooltip-inner {
  max-width: 100%;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #22a96c;
  }
}
.dateTime {
  width: 372px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    width: 50px;
    height: 40px;
    line-height: 40px;
    margin: 6px;
    text-align: center;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #72a2d4;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.dateWeek {
  width: 550px;
  display: inline-flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: flex-start;
  .ivu-checkbox-wrapper {
    flex: 1;
    &.ivu-checkbox-wrapper-checked.ivu-checkbox-border {
      background: #72a2d4;
      color: #fff;
    }
    /deep/.ivu-checkbox {
      display: none;
    }
  }
}
.tempContainer {
  /deep/.ivu-tabs-content {
    height: calc(100vh - 122px);
    .tempBox {
      height: calc(100% - 35px);
      overflow: auto;
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      & > div {
        width: 32%;
        height: 33%;
        min-height: 211px;
        margin: 0.5%;
        .ivu-card-head {
          padding: 10px;
          text-align: center;
        }
        .ivu-card-body {
          padding: 10px;
          line-height: 30px;
          min-height: 142px;
          height: 100%;
          overflow: auto;
        }
      }
      .noList {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
